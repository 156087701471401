// @flow

import _ from 'underscore';
import { BaseService } from '@performant-software/shared-components';
import Transmission from '../transforms/Transmission';

/**
 * Service class for all transmission based API calls.
 */
class Transmissions extends BaseService {
  /**
   * Fetches the transmissions from the API and returns the transformed objects on the response.
   *
   * @param params
   *
   * @returns {Promise<R>|Promise<unknown>}
   */
  fetchAll(params: any) {
    return super.fetchAll(params).then((response) => {
      _.each(response.data.transmissions, (transmission, index) => {
        response.data.transmissions[index] = Transmission.toTransmission(transmission);
      });

      return response;
    });
  }

  /**
   * Returns the transmissions base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/transmissions';
  }

  /**
   * Returns the transmission transform object.
   *
   * @returns {Transmission}
   */
  getTransform() {
    return Transmission;
  }
}

export default new Transmissions();
