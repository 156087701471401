// @flow

import React from 'react';
import { DescriptorField } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import '../styles/Arabic.css';

import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  chapter: number,
  standardReading: string,
  verse: number
};

const ChapterVerse = ({
  chapter, standardReading, verse
}: Props) => (
  <DescriptorField
    popupContent={(
      <div className='arabic'>
        <span className='arabic-text'>{ standardReading }</span>
      </div>
    )}
    content={`${chapter} : ${verse}`}
  />
);

export default withTranslation()(ChapterVerse);
