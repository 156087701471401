// @flow

import React from 'react';
import { DescriptorField } from '@performant-software/semantic-components';
import uuid from 'uuid';
import _ from 'underscore';

import type { Transmission as TransmissionType } from '../types/Transmission';

const ARABIC_NAME_SEPARATOR = ' \u{2190} ';
const NAME_SEPARATOR = ' \u{2192} ';

/**
 * Class for handling transforming transmission records.
 */
class Transmission {
  /**
   * Returns a copy of the passed transmission.
   *
   * @param transmission
   *
   * @returns {{transmission_memberships: any[]}}
   */
  toCopy(transmission: TransmissionType) {
    return {
      ..._.omit(transmission, 'id'),
      transmission_memberships: _.map(transmission.transmission_memberships, (tm) => ({
        ..._.omit(tm, 'id'),
        uid: uuid.v4()
      }))
    };
  }

  /**
   * Transforms the passed transmission into a dropdown option.
   *
   * @param transmission
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(transmission: TransmissionType = {}) {
    return {
      key: transmission.id,
      value: transmission.id,
      content: <DescriptorField content={transmission.name} popupContent={transmission.name} />
    };
  }

  /**
   * Returns the transmission object to be sent to the server on POST/PUT requests.
   *
   * @param transmission
   *
   * @returns {*}
   */
  toPayload(transmission: TransmissionType) {
    // Set the sequence for all non-deleted transmission memberships
    let sequence = -1;

    const items = _.map(transmission.transmission_memberships, (tm) => {
      if (tm._destroy) {
        return tm;
      }

      sequence += 1;

      return {
        ...tm,
        sequence
      };
    });

    return {
      transmission: {
        transmission_memberships: _.map(items,
          (item) => _.pick(item, 'id', 'person_id', 'location_id', 'certitude', 'sequence', '_destroy'))
      }
    };
  }

  /**
   * Parses the transmission name JSON and returns the transmission object.
   *
   * @param transmission
   *
   * @returns {{name: string}}
   */
  toTransmission(transmission: TransmissionType) {
    let name = null;
    let arabicName = null;

    try {
      name = JSON.parse(transmission.name).join(NAME_SEPARATOR);
      arabicName = JSON.parse(transmission.arabic_name).join(ARABIC_NAME_SEPARATOR);
    } catch (e) {
      // Do nothing, unable to parse the name. This should not happen.
    }

    return {
      ...transmission,
      name,
      arabic_name: arabicName
    };
  }
}

export default new Transmission();
