// @flow

import React, { Component } from 'react';
import { KeyboardField, LinkLabel } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Form, Transition } from 'semantic-ui-react';
import './TranslateField.css';

type Input = {
  className?: string,
  error?: boolean,
  id?: string,
  label: string,
  name?: string,
  onChange: (value: string) => void,
  onKeyDown?: (e: Event) => void,
  required: boolean,
  value: string
};

type Props = {
  children: Component<{}>,
  inputField: Input & { autoFocus: boolean },
  t: (key: string) => string,
  translationField: Input & { layout: any }
};

type State = {
  showTranslation: boolean
};

class TranslateField extends Component<Props, State> {
  /**
   * Constructs a new TranslateField component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showTranslation: false
    };
  }

  /**
   * Renders the TranslateField component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Form.Field
        className='translate-field'
      >
        { this.renderInputField() }
        <Transition
          duration={{
            hide: 100,
            show: 500
          }}
          visible={this.state.showTranslation}
        >
          { this.renderTranslationField() }
        </Transition>
      </Form.Field>
    );
  }

  /**
   * Renders the main input field.
   *
   * @returns {*}
   */
  renderInputField() {
    return (
      <Form.Input
        autoFocus={this.props.inputField.autoFocus}
        className={this.props.inputField.className || ''}
        error={this.props.inputField.error}
        id={this.props.inputField.id}
        label={this.renderLabel()}
        name={this.props.inputField.name}
        onChange={this.props.inputField.onChange.bind(this)}
        onKeyDown={(e) => this.props.inputField.onKeyDown && this.props.inputField.onKeyDown(e)}
        placeholder=''
        required={this.props.inputField.required}
        type='text'
        value={this.props.inputField.value}
      />
    );
  }

  /**
   * Renders the input label.
   *
   * @returns {*}
   */
  renderLabel() {
    return (
      <LinkLabel
        className='translate-label'
        content={this.state.showTranslation
          ? this.props.t('TranslateField.labels.hide')
          : this.props.t('TranslateField.labels.translate')}
        htmlFor={this.props.inputField.name}
        label={this.props.inputField.label}
        onClick={() => this.setState((state) => ({ showTranslation: !state.showTranslation }))}
      />
    );
  }

  /**
   * Renders the translation field.
   *
   * @returns {null|*}
   */
  renderTranslationField() {
    // If a keyboard layout is specified, render the keyboard field.
    if (this.props.translationField.layout) {
      return (
        <KeyboardField
          className={`translate-input ${this.props.translationField.className || ''}`}
          error={this.props.translationField.error}
          id={this.props.translationField.id}
          label={this.props.translationField.label}
          layout={this.props.translationField.layout}
          name={this.props.translationField.name}
          onChange={this.props.translationField.onChange.bind(this)}
          onKeyDown={(e) => this.props.translationField.onKeyDown && this.props.translationField.onKeyDown(e)}
          required={this.props.translationField.required}
          value={this.props.translationField.value}
          visible={this.state.showTranslation}
        >
          { this.props.children }
        </KeyboardField>
      );
    }

    // Otherwise, render a regular input field
    return (
      <Form.Input
        className={`translate-input ${this.props.translationField.className || ''}`}
        error={this.props.translationField.error}
        id={this.props.translationField.id}
        label={this.props.translationField.label}
        name={this.props.translationField.name}
        onChange={this.props.translationField.onChange.bind(this)}
        placeholder=''
        required={this.props.translationField.required}
        type='text'
        value={this.props.translationField.value}
      >
        { this.props.children }
      </Form.Input>
    );
  }
}

export default withTranslation()(TranslateField);
