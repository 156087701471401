// @flow

import uuid from 'uuid';
import _ from 'underscore';

import type { Manual as ManualType } from '../types/Manual';

/**
 * Class for handling transforming manual records.
 */
class Manual {
  /**
   * Returns a copy of the passed manual.
   *
   * @param manual
   *
   * @returns {{authorships: any[]}}
   */
  toCopy(manual: ManualType) {
    return {
      ..._.omit(manual, 'id'),
      authorships: _.map(manual.authorships, (authorship) => ({
        ..._.omit(authorship, 'id'),
        uid: uuid.v4()
      }))
    };
  }

  /**
   * Transforms the passed manual into a dropdown option.
   *
   * @param manual
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(manual: ManualType = {}) {
    return {
      key: manual.id,
      value: manual.id,
      text: manual.name
    };
  }

  /**
   * Returns the manual object to be sent to the server on POST/PUT requests.
   *
   * @param manual
   *
   * @returns {*}
   */
  toPayload(manual: ManualType) {
    return {
      manual: {
        ..._.pick(manual, 'name', 'arabic_name', 'publication_date', 'publication_location_id'),
        authorships: _.map(manual.authorships, ((a) => _.pick(a, 'id', 'person_id', 'role', '_destroy')))
      }
    };
  }
}

export default new Manual();
