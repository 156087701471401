// @flow

import React, { useEffect, useState } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import {
  Dimmer,
  Loader
} from 'semantic-ui-react';
import Pages from '../services/Pages';
import './Category.css';

const Category = () => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  // Display name for the category
  const [categoryName, setCategoryName] = useState('');

  const { categorySlug } = useParams();

  /**
   * Fetch the static page based on the route.
   */
  useEffect(() => {
    setLoading(true);

    Pages
      .fetchAll({ category: categorySlug })
      .then(({ data }) => {
        setPages(data.pages);
        if (data.pages[0]) {
          setCategoryName(data.pages[0].category_view);
        } else {
          // Fall back to slug from URL if the category doesn't load
          setCategoryName(categorySlug);
        }
      })
      .finally(() => setLoading(false));
  }, [categorySlug]);

  return (
    <div className='categories-page'>
      { loading && (
      <Dimmer active inverted page>
        <Loader />
      </Dimmer>
      )}
      <header className='blk-bg'>
        <h1 className='page-title' data-aos='fade-in'>{categoryName}</h1>
      </header>
      <main>
        {pages.map((page) => (
          <div
            className='thumb-container'
            key={page.id}
          >
            <h3><Link to={`/pages/${categorySlug}/${page.slug}`}>{page.title}</Link></h3>
          </div>
        ))}
      </main>
    </div>
  );
};

export default withRouter(Category);
