// @flow

import React, { Component } from 'react';
import { Modal, Table } from 'semantic-ui-react';
import _ from 'underscore';
import Verses from '../services/Verses';
import './VersesModal.css';

import type { Word } from '../types/Word';
import type { Verse } from '../types/Verse';

type Props = {
  onClose: () => void,
  visible: boolean,
  words: Array<Word>
};

type State = {
  verses: Array<Verse>
};

class VersesModal extends Component<Props, State> {
  /**
   * Constructs a new VersesModal component.
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      verses: []
    };
  }

  /**
   * Lazy loads the verses for the selected words.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: Props) {
    if (!prevProps.visible && this.props.visible && !this.state.verses.length) {
      Verses
        .fetchAll({ word_ids: _.pluck(this.props.words, 'id') })
        .then(({ data: { verses } }) => this.setState({ verses }));
    }
  }

  /**
   * Renders the VersesModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        className='verses-modal'
        closeIcon
        onClose={this.props.onClose.bind(this)}
        open={this.props.visible}
        size='large'
      >
        <Modal.Content>
          <Table
            striped
          >
            <Table.Body>
              { this.state.verses.map(this.renderVerseRow.bind(this)) }
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }

  /**
   * Renders the row for the passed verse.
   *
   * @param verse
   *
   * @returns {*}
   */
  renderVerseRow(verse: Verse) {
    return (
      <Table.Row
        key={verse.id}
      >
        <Table.Cell
          className='verse-reference'
        >
          {`${verse.chapter.number}:${verse.number}`}
        </Table.Cell>
        <Table.Cell
          className='arabic-cell'
          dir='rtl'
          textAlign='right'
        >
          { verse.words.map(this.renderVerseWord.bind(this)) }
        </Table.Cell>
      </Table.Row>
    );
  }

  /**
   * Renders the passed word.
   *
   * @param word
   *
   * @returns {*}
   */
  renderVerseWord(word: Word) {
    const classNames = ['arabic-text'];

    if (_.filter(this.props.words, (w) => w.id === word.id).length) {
      classNames.push('arabic-text-selected');
    }

    return (
      <span
        className={classNames.join(' ')}
        key={word.id}
      >
        { word.arabic_without_vowels }
      </span>
    );
  }
}

export default VersesModal;
