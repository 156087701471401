// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import VariantTypeModal from '../components/VariantTypeModal';
import VariantTypesService from '../services/VariantTypes';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';

type Props = {
  t: (key: string) => string
};

const VariantTypes = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    collectionName='variant_types'
    columns={[{
      name: 'abbreviation',
      label: props.t('VariantTypes.columns.abbreviation'),
      sortable: true
    }, {
      name: 'name',
      label: props.t('VariantTypes.columns.name'),
      sortable: true
    }, {
      name: 'description',
      label: props.t('VariantTypes.columns.description'),
      sortable: true
    }]}
    modal={{
      component: VariantTypeModal,
      props: {
        required: ['abbreviation', 'description', 'name'],
      }
    }}
    onDelete={(variantType) => VariantTypesService.delete(variantType)}
    onLoad={(params) => VariantTypesService.fetchAll(params)}
    onSave={(variantType) => VariantTypesService.save(variantType)}
    perPageOptions={[10, 25, 50, 100]}
    session={{
      key: 'variantTypes',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(VariantTypes);
