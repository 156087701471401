// @flow

import { BaseService } from '@performant-software/shared-components';
import Variant from '../transforms/Variant';

/**
 * Service class for all variant based API calls.
 */
class Variants extends BaseService {
  /**
   * Returns the variants base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/variants';
  }

  /**
   * Returns the config properties for variants POST/PUT requests.
   *
   * @returns {{headers: {'Content-Type': string}}}
   */
  getConfig() {
    return { headers: { 'Content-Type': 'multipart/form-data' } };
  }

  /**
   * Returns the variants transform object.
   *
   * @returns {Variant}
   */
  getTransform() {
    return Variant;
  }
}

export default new Variants();
