// @flow

import _ from 'underscore';

import type { Principle as PrincipleType } from '../types/Principle';

/**
 * Class for handling transforming principle records.
 */
class Principle {
  /**
   * Transforms the passed principle into a dropdown option.
   *
   * @param principle
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(principle: PrincipleType = {}) {
    return {
      key: principle.id,
      value: principle.id,
      text: principle.name
    };
  }

  /**
   * Returns the principle object to be sent to the server on POST/PUT requests.
   *
   * @param principle
   *
   * @returns {*}
   */
  toPayload(principle: PrincipleType) {
    return { principle: _.pick(principle, 'name', 'rule', 'color') };
  }
}

export default new Principle();
