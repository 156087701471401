import { BaseService } from '@performant-software/shared-components';
import _ from 'underscore';

// Sets the authentication token as a request header
BaseService.configure((axios) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_API_URL) {
      _.extend(axios.defaults, { baseURL: process.env.REACT_APP_API_URL });
    }
  }

  axios.interceptors.request.use((config) => {
    const user = sessionStorage.getItem('user');

    // Set the user authentication token
    if (user) {
      _.extend(config.headers, JSON.parse(user));
    }

    return config;
  }, (error) => Promise.reject(error));
}, (error) => Promise.reject(error));
