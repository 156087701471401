// @flow

import uuid from 'uuid';
import _ from 'underscore';

import type { Footnote as FootnoteType } from '../types/Footnote';

/**
 * Class for handling transforming footnote records.
 */
class Footnote {
  /**
   * Adds the UID property to the passed footnote object.
   *
   * @param footnote
   *
   * @returns {{uid: *}}
   */
  toFootnote(footnote: FootnoteType) {
    return {
      uid: uuid.v4(),
      ...footnote
    };
  }

  /**
   * Converts the passed footnotes string into an array of objects.
   *
   * @param footnotes
   *
   * @returns {*[]|*}
   */
  toFootnotes(footnotes: string) {
    if (!footnotes) {
      return [];
    }

    return JSON.parse(footnotes).map(this.toFootnote.bind(this));
  }

  /**
   * Converts the passed array of footnote objects into a JSON string.
   *
   * @param footnotes
   *
   * @returns {string|null}
   */
  toPayload(footnotes: Array<FootnoteType>) {
    if (!(footnotes && footnotes.length)) {
      return null;
    }

    return JSON.stringify(_.map(footnotes, (footnote) => _.pick(footnote, 'body', 'label', 'language')));
  }
}

export default new Footnote();
