// @flow

import { BaseService } from '@performant-software/shared-components';
import Location from '../transforms/Location';

/**
 * Service class for all location based API calls.
 */
class Locations extends BaseService {
  /**
   * Returns the locations base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/locations';
  }

  /**
   * Returns the locations transform object.
   *
   * @returns {Location}
   */
  getTransform() {
    return Location;
  }
}

export default new Locations();
