// @flow

import _ from 'underscore';

import type { Person as PersonType } from '../types/Person';

/**
 * Class for handling transforming person records.
 */
class Person {
  /**
   * Transforms the passed person into a dropdown option.
   *
   * @param person
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(person: PersonType = {}) {
    return {
      key: person.id,
      value: person.id,
      text: person.abbreviation,
      description: person.name
    };
  }

  /**
   * Returns the person object to be sent to the server on POST/PUT requests.
   *
   * @param person
   *
   * @returns {*}
   */
  toPayload(person: PersonType) {
    return {
      person: _.pick(person, [
        'name',
        'arabic_name',
        'arabic_abbreviation',
        'abbreviation',
        'birth_date',
        'birth_location_id',
        'death_date',
        'death_location_id'
      ])
    };
  }
}

export default new Person();
