// @flow

import React from 'react';
import { ListTable } from '@performant-software/semantic-components';
import ScrollViewContext from '../contexts/ScrollViewContext';

type Props = {
  onLoad: (params: any) => Promise<any>
};

const ScrollableListTable = (props: Props) => (
  <ScrollViewContext.Consumer>
    { (context) => (
      <ListTable
        {...props}
        onLoad={(params) => {
          if (context.scrollToTop) {
            context.scrollToTop();
          }

          return props.onLoad(params);
        }}
      />
    )}
  </ScrollViewContext.Consumer>
);

export default ScrollableListTable;
