// @flow

import React, { useState } from 'react';
import { LinkLabel, ReferenceCodeDropdown } from '@performant-software/semantic-components';
import RichTextArea from './RichTextArea';
import { withTranslation } from 'react-i18next';
import { Form, Modal, TextArea } from 'semantic-ui-react';
import './PageModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Page } from '../types/Page';
import type { Translateable } from '../types/Translateable';

type Props = EditContainerProps & Translateable & {
  item: Page
};

const Modes = {
  richText: 0,
  rawHtml: 1
};

const PageModal = (props: Props) => {
  const [mode, setMode] = useState(Modes.richText);

  return (
    <Modal
      as={Form}
      centered={false}
      className='page-modal'
      noValidate
      open
    >
      <Modal.Header
        content={props.t('PageModal.title')}
      />
      <Modal.Content>
        <Form.Input
          error={props.isError('title')}
          label={props.t('PageModal.labels.title')}
          onChange={props.onTextInputChange.bind(this, 'title')}
          required={props.isRequired('title')}
          value={props.item.title || ''}
        />
        <Form.Input
          error={props.isError('category')}
          label={props.t('PageModal.labels.category')}
          required={props.isRequired('category')}
        >
          <ReferenceCodeDropdown
            className='categories-dropdown'
            onChange={props.onMultiAddChildAssociations.bind(this, 'category')}
            referenceTable='category'
            required={props.isRequired('category')}
            value={props.item.category}
          />
        </Form.Input>
        <Form.Input
          error={props.isError('content')}
          label={(
            <LinkLabel
              content={mode === Modes.richText
                ? props.t('PageModal.labels.showHtml')
                : props.t('PageModal.labels.showContent')}
              label={props.t('PageModal.labels.content')}
              onClick={() => setMode(mode === Modes.richText ? Modes.rawHtml : Modes.richText)}
            />
          )}
          required={props.isRequired('content')}
        >
          { mode === Modes.richText && (
            <RichTextArea
              onChange={(value) => props.onTextInputChange('content', null, { value })}
              value={props.item.content || ''}
              visible={mode === Modes.richText}
            />
          )}
          { mode === Modes.rawHtml && (
            <TextArea
              className='code'
              onChange={(e, { value }) => props.onTextInputChange('content', null, { value })}
              rows={20}
              value={props.item.content || ''}
            />
          )}
        </Form.Input>
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(PageModal);
