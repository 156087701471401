// @flow

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import hexCodes from '../resources/HexCodes.json';
import ImageResize from 'quill-image-resize-module-react';
import { Icon } from 'semantic-ui-react';
import AudioBlot from './AudioBlot';
import TweetBlot from './TweetBlot';
import 'react-quill/dist/quill.snow.css';
import './RichTextArea.css';

import type { Translateable } from '../types/Translateable';

Quill.register('modules/imageResize', ImageResize);
Quill.register(AudioBlot);
Quill.register(TweetBlot)

type Props = Translateable & {
  onChange?: (value: string) => void,
  placeholder?: string,
  readOnly?: boolean,
  theme?: string,
  value: ?string
};

const SEARCH_EMPTY = '<p><br></p>';
const REPLACE_EMPTY = '';

const icons = Quill.import('ui/icons');
icons.audio = ReactDOMServer.renderToStaticMarkup(<Icon name='volume up' />);
icons.tweet = ReactDOMServer.renderToStaticMarkup(<Icon name='twitter' />);

const RichTextArea = (props: Props) => {
  const quillRef = useRef();
  const fileInputRef = useRef();

  /**
   * Sets the imageResize attribute in edit mode only.
   *
   * @type {{parchment: any, modules: string[]}}
   */
  const imageResize = useMemo(() => (props.readOnly ? undefined : ({
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  })), [props.readOnly]);

  const modules = useMemo(() => ({
    imageResize,
    toolbar: {
      container: [
        [{
          header: '1'
        }, {
          header: '2'
        }, {
          font: [
            'sans serif',
            'serif',
            'monospace'
          ]
        }, {
          size: []
        }], [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
        ], [{
          color: hexCodes
        }], [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }, {
          indent: '-1'
        }, {
          indent: '+1'
        }, {
          align: ''
        }, {
          align: 'center'
        }, {
          align: 'right'
        }, {
          align: 'justify'
        }, {
          direction: 'rtl'
        }], [
          'link',
          'image',
          'audio',
          'tweet',
          'clean'
        ]
      ],
      handlers: {
        audio: () => fileInputRef && fileInputRef.current && fileInputRef.current.click()
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [fileInputRef]);

  /**
   * Converts the passed file to base64 format.
   *
   * @param file
   *
   * @returns {Promise<unknown>}
   */
  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  /**
   * Converts the selected file to base64 and embeds the audio in the editor.
   *
   * @param event
   *
   * @returns {Promise<void>}
   */
  const onFileChange = async (event) => {
    const { current } = quillRef;

    if (current) {
      const file = event.target.files.item(0);
      const data = await toBase64(file);
      const quill = current.getEditor();

      const range = quill.getSelection();
      const position = range ? range.index : 0;

      quill.insertEmbed(position, 'audio', data);
    }
  };

  /**
   * Sets the title attribute on the toolbar element matching the passed selector.
   *
   * @type {(function(*, *, *): void)|*}
   */
  const setToolbarAttribute = useCallback((toolbar, selector, label) => {
    const button = toolbar.querySelector(selector);
    if (button) {
      button.setAttribute('title', props.t(`RichTextArea.toolbar.${label}`));
    }
  }, []);

  /**
   * Sets the "title" attribute on all of the toolbar elements.
   */
  useEffect(() => {
    if (quillRef.current) {
      const { container } = quillRef.current.editor;
      const toolbar = container.previousSibling;

      if (toolbar) {
        setToolbarAttribute(toolbar, 'button.ql-header[value="2"]', 'h2');
        setToolbarAttribute(toolbar, 'button.ql-header[value="1"]', 'h1');
        setToolbarAttribute(toolbar, '.ql-font.ql-picker', 'fontFace');
        setToolbarAttribute(toolbar, '.ql-size.ql-picker', 'fontSize');
        setToolbarAttribute(toolbar, 'button.ql-bold', 'bold');
        setToolbarAttribute(toolbar, 'button.ql-italic', 'italic');
        setToolbarAttribute(toolbar, 'button.ql-underline', 'underline');
        setToolbarAttribute(toolbar, 'button.ql-strike', 'strike');
        setToolbarAttribute(toolbar, 'button.ql-blockquote', 'block');
        setToolbarAttribute(toolbar, '.ql-color.ql-picker', 'color');
        setToolbarAttribute(toolbar, 'button.ql-list[value="ordered"]', 'list');
        setToolbarAttribute(toolbar, 'button.ql-list[value="bullet"]', 'bullet');
        setToolbarAttribute(toolbar, 'button.ql-indent[value="-1"]', 'indentDecrease');
        setToolbarAttribute(toolbar, 'button.ql-indent[value="+1"]', 'indentIncrease');
        setToolbarAttribute(toolbar, 'button.ql-align[value=""]', 'alignLeft');
        setToolbarAttribute(toolbar, 'button.ql-align[value="center"]', 'alignCenter');
        setToolbarAttribute(toolbar, 'button.ql-align[value="right"]', 'alignRight');
        setToolbarAttribute(toolbar, 'button.ql-align[value="justify"]', 'alignJustify');
        setToolbarAttribute(toolbar, 'button.ql-direction', 'direction');
        setToolbarAttribute(toolbar, 'button.ql-link', 'link');
        setToolbarAttribute(toolbar, 'button.ql-image', 'image');
        setToolbarAttribute(toolbar, 'button.ql-audio', 'audio');
        setToolbarAttribute(toolbar, 'button.ql-clean', 'clear');
      }
    }
  }, [quillRef.current]);

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        onChange={onFileChange}
        style={{
          display: 'none'
        }}
      />
      <ReactQuill
        className='rich-text-area'
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'color',
          'list',
          'bullet',
          'direction',
          'indent',
          'align',
          'link',
          'image',
          'video',
          'audio',
          'alt',
          'height',
          'width',
          'style',
          'size',
          'tweet'
        ]}
        modules={modules}
        onChange={(value) => {
          let newValue = value;

          if (value === SEARCH_EMPTY) {
            newValue = REPLACE_EMPTY;
          }

          if (props.onChange) {
            props.onChange(newValue);
          }
        }}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
        ref={quillRef}
        theme={props.theme}
        value={props.value}
      />
    </>
  );
};

RichTextArea.defaultProps = {
  onChange: () => {},
  placeholder: undefined,
  readOnly: false,
  theme: 'snow'
};

export default withTranslation()(RichTextArea);
export type { Props as RichTextAreaProps };
