// @flow

import React from 'react';
import { useDragDrop } from '@performant-software/shared-components';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Category from './Category';
import Entry from './Entry';
import Home from './Home';
import Layout from '../components/Layout';
import Page from './Page';
import '../styles/Arabic.css';

const App = () => (
  <Router>
    <Route exact path='/'>
      <Layout>
        <Home />
      </Layout>
    </Route>
    <Route path='/entry' component={Entry} />
    <Route exact path='/pages/:categorySlug/:pageSlug'>
      <Layout>
        <Page />
      </Layout>
    </Route>
    <Route exact path='/pages/:categorySlug'>
      <Layout>
        <Category />
      </Layout>
    </Route>
  </Router>
);

export default useDragDrop(App);
