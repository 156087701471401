// @flow

import uuid from 'uuid';
import _ from 'underscore';

import type { Variant as VariantType } from '../types/Variant';

const PAYLOAD_KEYS = [
  'id',
  'arabic_with_vowels',
  'offset',
  'roman_transliteration',
  'standard',
  'standard_reading',
  'start_word_id',
  'status'
];

const ATTACHMENT_PAYLOAD_KEYS = [
  'id',
  'file',
  '_destroy'
];

const VARIANT_TYPES_VARIANT_PAYLOAD_KEYS = [
  'id',
  'variant_id',
  'variant_type_id',
  '_destroy'
];

/**
 * Class for handling transforming variant records.
 */
class Variant {
  /**
   * Returns a copy of the passed variant. The related attachments are not copied.
   *
   * @param variant
   */
  toCopy(variant: VariantType) {
    return {
      ..._.omit(variant, 'id'),
      variant_types_variants: _.map(variant.variant_types_variants, (vtv) => ({
        ..._.omit(vtv, 'id'),
        uid: uuid.v4()
      }))
    };
  }

  /**
   * Transforms the passed variant into a dropdown option.
   *
   * @param variant
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(variant: VariantType = {}) {
    return {
      key: variant.id,
      value: variant.id,
      text: variant.arabic_with_vowels,
      description: variant.roman_transliteration
    };
  }

  /**
   * Returns the variant object to be sent to the server on POST/PUT requests.
   *
   * @param variant
   *
   * @returns {*}
   */
  toPayload(variant: VariantType) {
    const formData = new FormData();

    const toString = (value) => {
      if (_.isNumber(value) || _.isBoolean(value)) {
        return value;
      }

      return value || '';
    };

    // Append the variant values to the form data
    _.each(PAYLOAD_KEYS, (key) => {
      formData.append(`variant[${key}]`, toString(variant[key]));
    });

    // Append the attachment values to the form data
    const attachments = _.filter(variant.attachments, (a) => !a.id || a._destroy);

    _.each(attachments, (attachment, index) => {
      _.each(ATTACHMENT_PAYLOAD_KEYS, (key) => {
        formData.append(`variant[attachments][${index}][${key}]`, toString(attachment[key]));
      });
    });

    // Append the variant types to the form data
    _.each(variant.variant_types_variants, (vtv, index) => {
      _.each(VARIANT_TYPES_VARIANT_PAYLOAD_KEYS, (key) => {
        formData.append(`variant[variant_types_variants][${index}][${key}]`, toString(vtv[key]));
      });
    });

    return formData;
  }
}

export default new Variant();
