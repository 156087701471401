// @flow

import React, { useCallback, useMemo } from 'react';
import { Popup } from 'semantic-ui-react';
import { DescriptorField } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import AnnotationModal from './AnnotationModal';
import Annotations from '../services/Annotations';
import AnnotationsFilters from './AnnotationsFilters';
import Annotation from '../transforms/Annotation';
import ChapterVerse from './ChapterVerse';
import { getAnchorProps } from '../utils/Words';
import ScrollableListTable from './ScrollableListTable';
import './AnnotationsList.css';

import type { Translateable } from '../types/Translateable';
import type { Word } from '../types/Word';

type Props = Translateable & {
  buttons?: Array<any>,
  filters?: any,
  onFilterChange?: (filters: any) => void,
  publicView: boolean,
  words: Array<Word>
};

const ERROR_PRINCIPLE_OR_VARIANT = 'Principle or variant is required';
const ERROR_UNIQUE_ANNOTATION_PRINCIPLE = 'Principle, transmission, manual, start word, and offset must be unique';
const ERROR_UNIQUE_ANNOTATION_VARIANT = 'Variant, transmission, and manual must be unique';

const AnnotationsList = (props: Props) => {
  /**
   * Returns the formatted error message for the passed error and annotation.
   *
   * @param error
   * @param item
   *
   * @returns {null|*}
   */
  const resolveValidationError = (error, item) => {
    if (error === ERROR_UNIQUE_ANNOTATION_VARIANT) {
      const { manual, transmission, variant } = item;
      return {
        variant_id: props.t('AnnotationsList.errors.unique.variant', {
          manual: manual.name,
          transmission: transmission.name,
          variant: variant.arabic_with_vowels
        })
      };
    }

    if (error === ERROR_UNIQUE_ANNOTATION_PRINCIPLE) {
      // eslint-disable-next-line camelcase
      const { manual, transmission, principle_membership } = item;
      return {
        principle_membership_id: props.t('AnnotationsList.errors.unique.principle', {
          manual: manual.name,
          principle: principle_membership.principle && principle_membership.principle.name,
          transmission: transmission.name
        })
      };
    }

    if (error === ERROR_PRINCIPLE_OR_VARIANT) {
      return {
        principle_variant: props.t('AnnotationsList.errors.variantRequired')
      };
    }

    return null;
  };

  /**
   * Renders the footnotes table cell.
   *
   * @param annotation
   *
   * @returns {null|*}
   */
  const renderFootnotes = (annotation) => {
    if (_.isEmpty(annotation.footnotes)) {
      return null;
    }

    return (
      <div>
        { _.map(annotation.footnotes, (footnote, index) => (
          <Popup
            className={footnote.language === 'ar' ? 'arabic fnPopup' : 'fnPopup'}
            content={footnote.body}
            hoverable
            key={index}
            trigger={(
              <span>
                { footnote.label + (annotation.footnotes.length - 1 !== index ? ', ' : '')}
              </span>
            )}
          />
        ))}
      </div>
    );
  };

  /**
   * Validates the passed annotation for the presence of a variant.
   *
   * @param annotation
   *
   * @returns {[]}
   */
  const validate = (annotation) => {
    const validationErrors = {};

    const hasVariant = !!annotation.variant_id;

    if (!hasVariant) {
      _.extend(validationErrors, { principle_variant: props.t('AnnotationsList.errors.variantRequired') });
    }

    return validationErrors;
  };

  /**
   * Returns the offset and start_word_id parameters object, or the word_id and filters.
   *
   * @returns {{offset: number, start_word_id: number} | {word_id: number, *}}
   */
  const getParams = useCallback(() => {
    if (props.publicView) {
      return {};
    }

    const anchorProps = getAnchorProps(props.words);

    return {
      ...anchorProps,
      has_variant: true, // Hides principle_memberships from table
    };
  }, [props.publicView, props.words]);

  const sharedColumns = [{
    className: 'chapter-verse',
    name: 'chapter_verse',
    label: props.t('Common.columns.chapterVerse'),
    render: (annotation) => (
      <ChapterVerse
        chapter={annotation.chapter}
        verse={annotation.verse}
        standardReading={annotation.variant.standard_reading}
      />
    ),
    sortable: true
  }, {
    className: 'arabic',
    name: 'variants.arabic_with_vowels',
    label: props.t('AnnotationsList.columns.arabic'),
    resolve: (annotation) => annotation.variant.arabic_with_vowels,
    sortable: true
  }, {
    className: 'transmission-name',
    name: 'transmissions.name',
    label: props.t('AnnotationsList.columns.transmission'),
    render: (annotation) => (
      <DescriptorField
        className='arabic'
        content={annotation.transmission.name}
        popupContent={annotation.transmission.arabic_name}
      />
    ),
    sortable: true
  }, {
    name: 'manuals.name',
    className: 'manual-name',
    label: props.t('AnnotationsList.columns.manual'),
    resolve: (annotation) => annotation.manual.name,
    sortable: true
  }, {
    name: 'page_number',
    className: 'page-number',
    label: props.t('AnnotationsList.columns.page'),
    sortable: true
  }, {
    name: 'footnotes',
    className: 'footnotes',
    label: props.t('AnnotationsList.columns.footnotes'),
    render: renderFootnotes.bind(this),
    sortable: true
  }];

  const adminColumns = [{
    name: 'variants.roman_transliteration',
    label: props.t('AnnotationsList.columns.roman_transliteration'),
    resolve: (annotation) => annotation.variant.roman_transliteration,
    sortable: true
  }];

  const [first, ...remaining] = sharedColumns;
  const combinedColumns = [first, ...adminColumns, ...remaining];

  const adminListTableProps = {
    actions: [{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }],
    modal: {
      component: AnnotationModal,
      props: {
        resolveValidationError: resolveValidationError.bind(this),
        required: ['manual_id', 'transmission_id'],
        validate: validate.bind(this),
        words: props.words
      }
    },
    onCopy: (annotation) => Annotation.toCopy(annotation),
    onDelete: (annotation) => Annotations.delete(annotation),
    onSave: (annotation) => Annotations.save(annotation),
    session: {
      key: 'AnnotationsList',
      storage: sessionStorage
    }
  };

  /**
   * Sets the filters variable. In the public view and admin view, the AnnotationsFilters component will be displayed
   * to allow filtering the list of annotations.
   *
   * @type {unknown}
   */
  const filters = useMemo(() => ({
    component: AnnotationsFilters,
    defaults: {
      ...props.filters || {},
      word_id: props.publicView
        ? props.words && props.words.length && _.first(props.words).id
        : undefined
    },
    onChange: props.onFilterChange,
    props: {}
  }), [props.onFilterChange, props.publicView, props.words]);

  const onLoad = useCallback((params) => (
    Annotations.fetchAll(_.extend(params, getParams()))
  ), [getParams, props.publicView]);

  return (
    <ScrollableListTable
      className='annotations-list'
      collectionName='annotations'
      columns={props.publicView ? sharedColumns : combinedColumns}
      filters={filters}
      onLoad={onLoad}
      {...props.publicView ? {} : adminListTableProps}
      buttons={props.buttons}
      perPageOptions={[10, 25, 50, 100]}
    />
  );
};

AnnotationsList.defaultProps = {
  onFilterChange: undefined
};

export default withTranslation()(AnnotationsList);
