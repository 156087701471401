// @flow

const Colors = {
  black: 'black',
  white: 'white'
};

const RED_OFFSET = 0.299;
const BLUE_OFFSET = 0.587;
const GREEN_OFFSET = 0.114;
const MAX_OFFSET = 186;

/**
 * Returns the text color for the passed background color. Darker background colors will display with a white text
 * color, lighter background colors will display with a black text color.
 *
 * @param backgroundColor
 *
 * @returns {*}
 */
const getTextColor = (backgroundColor: string) => {
  let textColor;

  const [r, b, g] = hexToRgb(backgroundColor);

  if ((r * RED_OFFSET) + (b * BLUE_OFFSET) + (g * GREEN_OFFSET) > MAX_OFFSET) {
    textColor = Colors.black;
  } else {
    textColor = Colors.white;
  }

  return textColor;
};

/**
 * Converts the passed hex string value to RBG.
 *
 * @param hex
 *
 * @returns {Array<number>|*[]}
 */
const hexToRgb = (hex: string) => {
  if (!isValidHex(hex)) {
    return [];
  }

  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = hex.slice(1).match(new RegExp(`.{${chunkSize}}`, 'g'));
  return (hexArr || []).map((hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16));
};

/**
 * Returns true if the passed hex value is a valid hex string.
 *
 * @param hex
 *
 * @returns {boolean}
 */
const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

export default {
  getTextColor
};
