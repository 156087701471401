// @flow

import { BaseService } from '@performant-software/shared-components';
import Page from '../transforms/Page';

/**
 * Service class for all page based API calls.
 */
class Pages extends BaseService {
  /**
   * Returns the pages base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/pages';
  }

  /**
   * Returns the pages transform object.
   *
   * @returns {Page}
   */
  getTransform(): {} {
    return Page;
  }
}

export default new Pages();
