// @flow

import _ from 'underscore';

const DEFAULT_DECIMALS = 2;

/**
 * Class responsible for all number utility functions.
 */
class Numbers {
  /**
   * Returns the passed number rounded to two decimal places.
   *
   * @param n
   * @param decimals
   *
   * @returns {string}
   */
  round(n, decimals = DEFAULT_DECIMALS) {
    if (!_.isNumber(n)) {
      return '';
    }

    return n.toFixed(decimals);
  }
}

export default new Numbers();
