// @flow

import _ from 'underscore';
import { BaseService } from '@performant-software/shared-components';
import Annotation from '../transforms/Annotation';

/**
 * Service class for all manual based API calls.
 */
class Annotations extends BaseService {
  /**
   * Fetches the annotations from the API and returns the transformed objects on the response.
   *
   * @param params
   *
   * @returns {Promise<R>|Promise<unknown>}
   */
  fetchAll(params: any) {
    return super.fetchAll(params).then((response) => {
      _.each(response.data.annotations, (annotation, index) => {
        response.data.annotations[index] = Annotation.toAnnotation(annotation);
      });

      return response;
    });
  }

  /**
   * Returns the annotations base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/annotations';
  }

  /**
   * Returns the annotations transform object.
   *
   * @returns {Annotation}
   */
  getTransform() {
    return Annotation;
  }
}

export default new Annotations();
