// @flow

import { BaseService } from '@performant-software/shared-components';
import Manual from '../transforms/Manual';

/**
 * Service class for all manual based API calls.
 */
class Manuals extends BaseService {
  /**
   * Returns the manuals base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/manuals';
  }

  /**
   * Returns the manuals transform object.
   *
   * @returns {Manual}
   */
  getTransform() {
    return Manual;
  }
}

export default new Manuals();
