// @flow

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import _ from 'underscore';
import FiltersHeader from './FiltersHeader';
import Manuals from '../services/Manuals';
import MultiSelect from './MultiSelect';
import TextAnchorFilter from './TextAnchorFilter';
import Transmissions from '../services/Transmissions';
import Words from '../services/Words';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Translateable } from '../types/Translateable';

type Props = EditContainerProps & Translateable & {
  item: {
    manual_id: Array<number>,
    transmission_id: Array<number>,
  },
  onReset: () => void
};

const AnnotationsFilters = (props: Props) => {
  const [word, setWord] = useState();

  /**
   * Load the word for the selected ID.
   */
  useEffect(() => {
    if (props.item.word_id) {
      Words
        .fetchAll({ ids: [props.item.word_id] })
        .then(({ data }) => setWord(_.first(data.words)));
    } else {
      setWord(null);
    }
  }, [props.item.word_id]);

  return (
    <Modal
      centered={false}
      open
    >
      <Modal.Header>
        <FiltersHeader
          content={props.t('AnnotationsFilters.title')}
          onReset={props.onReset}
        />
      </Modal.Header>
      <Modal.Content>
        { word && (
          <TextAnchorFilter
            onClear={() => props.onSetState({ word_id: undefined })}
            value={word.arabic_without_vowels}
          />
        )}
        <MultiSelect
          collectionName='manuals'
          onLoad={(params) => Manuals.fetchAll(params)}
          onChange={(manuals) => props.onSetState({ manual_id: _.pluck(manuals, 'id') })}
          renderItem={(manual) => manual.name}
          title={props.t('AnnotationsFilters.labels.manuals')}
          value={props.item.manual_id}
        />
        <MultiSelect
          collectionName='transmissions'
          onLoad={(params) => Transmissions.fetchAll(params)}
          onChange={(transmissions) => props.onSetState({ transmission_id: _.pluck(transmissions, 'id') })}
          renderItem={(transmission) => transmission.name}
          title={props.t('AnnotationsFilters.labels.transmissions')}
          value={props.item.transmission_id}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(AnnotationsFilters);
