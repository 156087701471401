// @flow

import { BaseService } from '@performant-software/shared-components';

/**
 * Service class responsible for all chapter based API calls.
 */
class Chapters extends BaseService {
  /**
   * Returns the chapters base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/chapters';
  }
}

export default new Chapters();
