// @flow

import _ from 'underscore';
import { type Word } from '../types/Word';

/**
 * Returns the offset and start_word_id from an array of words.
 *
 * @param words
 *
 * @returns {{offset: number, start_word_id: number}}
 */
const getAnchorProps = (words: Array<Word>) => {
  const first = _.min(words, (word) => word.id);
  const last = _.max(words, (word) => word.id);
  return {
    offset: last.id - first.id,
    start_word_id: first.id,
  };
};

export { getAnchorProps };
