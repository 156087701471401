// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import LocationModal from '../components/LocationModal';
import LocationsService from '../services/Locations';
import Numbers from '../utils/Numbers';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';
import './Locations.css';

type Props = {
  t: (key: string) => string
};

const Locations = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='locations'
    collectionName='locations'
    columns={[{
      name: 'name',
      label: props.t('Locations.columns.name'),
      sortable: true
    }, {
      className: 'arabic',
      name: 'arabic_name',
      label: props.t('Locations.columns.arabicName'),
      sortable: true
    }, {
      className: 'right',
      name: 'lat',
      label: props.t('Locations.columns.latitude'),
      resolve: ({ lat }) => Numbers.round(lat),
      sortable: true
    }, {
      className: 'right',
      name: 'lng',
      label: props.t('Locations.columns.longitude'),
      resolve: ({ lng }) => Numbers.round(lng),
      sortable: true
    }]}
    modal={{
      component: LocationModal,
      props: {
        required: ['name']
      }
    }}
    onDelete={(location) => LocationsService.delete(location)}
    onLoad={(params) => LocationsService.fetchAll(params)}
    onSave={(location) => LocationsService.save(location)}
    perPageOptions={[10, 25, 50, 100]}
    session={{
      key: 'locations',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(Locations);
