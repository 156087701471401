// @flow

import _ from 'underscore';

import type { VariantType as VariantTypeType } from '../types/VariantType';

class VariantType {
  /**
   * Converts the passed variant_type to a dropdown option.
   *
   * @param option
   * @param attributes
   *
   * @returns {{[p: string]: *}}
   */
  toDropdownOption(option: VariantTypeType, attributes: any = {}) {
    return {
      ...attributes,
      key: option.id,
      value: option.id,
      text: option.name,
      description: option.description,
    };
  }

  /**
   * Returns the variant_type object to be sent to the server on POST/PUT requests.
   *
   * @param variantType
   *
   * @returns {*}
   */
  toPayload(variantType: VariantTypeType) {
    return {
      variant_type: _.pick(variantType, 'id', 'name', 'abbreviation', 'description')
    };
  }
}

export default new VariantType();
