// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import './Footer.css';

import type { Translateable } from '../types/Translateable';

type Props = Translateable

const Footer = (props: Props) => (
  <footer className='footer-component'>
    <div className='credits'><p>{props.t('Footer.copyright')}</p></div>
  </footer>
);

export default withTranslation()(Footer);
