// @flow

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import FiltersHeader from './FiltersHeader';
import TextAnchorFilter from './TextAnchorFilter';
import VariantStatuses from '../resources/VariantStatuses.json';
import VariantType from '../transforms/VariantType';
import VariantTypes from '../services/VariantTypes';
import Words from '../services/Words';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Translateable } from '../types/Translateable';
import type { Word } from '../types/Word';

type Props = EditContainerProps & Translateable & {
  item: {
    status: Array<string>,
    variant_types: Array<string>,
    word: Word
  }
};

const VariantsFilters = (props: Props) => {
  const [query, setQuery] = useState(null);
  const [variantTypes, setVariantTypes] = useState([]);
  const [word, setWord] = useState();

  /**
   * Fetch the list of variant types from the API when the search string changes.
   */
  useEffect(() => {
    VariantTypes
      .fetchAll({
        per_page: 0,
        sort_by: 'abbreviation',
        search: query || null
      })
      .then(({ data }) => setVariantTypes(data.variant_types));
  }, [query]);

  /**
   * Load the word for the selected ID.
   */
  useEffect(() => {
    if (props.item.word_id) {
      Words
        .fetchAll({ ids: [props.item.word_id] })
        .then(({ data }) => setWord(_.first(data.words)));
    } else {
      setWord(null);
    }
  }, [props.item.word_id]);

  return (
    <Modal
      className='variants-filters'
      centered={false}
      open
    >
      <Modal.Header>
        <FiltersHeader
          content={props.t('VariantsFilters.title')}
          onReset={props.onReset}
        />
      </Modal.Header>
      <Modal.Content>
        { word && (
          <TextAnchorFilter
            onClear={() => props.onSetState({ word_id: undefined })}
            value={word.arabic_without_vowels}
          />
        )}
        <h4>
          { props.t('VariantsFilters.labels.status') }
        </h4>
        <Dropdown
          clearable
          deburr
          fluid
          placeholder={props.t('VariantsFilters.labels.placeholder')}
          multiple
          onChange={(e, { value }) => props.onSetState({ status: value })}
          search
          selection
          options={VariantStatuses}
          value={props.item.status || []}
        />
        <h4>
          { props.t('VariantsFilters.labels.type') }
        </h4>
        <Dropdown
          clearable
          deburr
          fluid
          placeholder={props.t('VariantsFilters.labels.placeholder')}
          multiple
          onChange={(e, { value }) => props.onSetState({ variant_types: value })}
          onSearchChange={(e, { searchQuery }) => setQuery(searchQuery)}
          search={options => options}
          selection
          options={_.map(variantTypes, VariantType.toDropdownOption.bind(this))}
          value={props.item.variant_types || []}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

export default withTranslation()(VariantsFilters);
