// @flow

import React from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import {
  Form,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import LocationModal from './LocationModal';
import TranslateField from './TranslateField';
import Locations from '../services/Locations';
import Location from '../transforms/Location';
import './PersonModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Person } from '../types/Person';

type Props = EditContainerProps & { item: Person };

const PersonModal = (props: Props) => (
  <Modal
    as={Form}
    open
    noValidate
    size='small'
  >
    <Modal.Header content={props.item.id
      ? props.t('PersonModal.title.editPerson')
      : props.t('PersonModal.title.addPerson')}
    />
    <Modal.Content>
      <TranslateField
        inputField={{
          autoFocus: true,
          error: props.isError('name'),
          id: 'name',
          label: props.t('PersonModal.labels.name'),
          onChange: props.onTextInputChange.bind(this, 'name'),
          required: props.isRequired('name'),
          value: props.item.name || ''
        }}
        translationField={{
          error: props.isError('arabic_name'),
          label: props.t('PersonModal.labels.arabicName'),
          layout: Arabic,
          onChange: props.onTextInputChange.bind(this, 'arabic_name'),
          required: props.isRequired('arabic_name'),
          value: props.item.arabic_name || ''
        }}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </TranslateField>
      <TranslateField
        inputField={{
          error: props.isError('abbreviation'),
          id: 'abbreviation',
          label: props.t('PersonModal.labels.abbreviation'),
          onChange: props.onTextInputChange.bind(this, 'abbreviation'),
          required: props.isRequired('abbreviation'),
          value: props.item.abbreviation || ''
        }}
        translationField={{
          error: props.isError('arabic_abbreviation'),
          label: props.t('PersonModal.labels.arabicAbbreviation'),
          layout: Arabic,
          onChange: props.onTextInputChange.bind(this, 'arabic_abbreviation'),
          required: props.isRequired('arabic_abbreviation'),
          value: props.item.arabic_abbreviation || ''
        }}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </TranslateField>
      <Form.Input
        error={props.isError('birth_date')}
        label={props.t('PersonModal.labels.birthDate')}
        onChange={props.onTextInputChange.bind(this, 'birth_date')}
        required={props.isRequired('birth_date')}
        value={props.item.birth_date || ''}
      />
      <Form.Input
        error={props.isError('birth_location_id')}
        label={props.t('PersonModal.labels.birthLocation')}
        required={props.isRequired('birth_location_id')}
      >
        <AssociatedDropdown
          collectionName='locations'
          modal={{
            component: LocationModal,
            props: {
              required: ['name']
            },
            onSave: (location) => Locations.save(location).then(({ data }) => data.location)
          }}
          onSearch={(search) => Locations.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'birth_location_id', 'birth_location')}
          renderOption={Location.toDropdownOption.bind(this)}
          searchQuery={props.item.birth_location && props.item.birth_location.name}
          value={props.item.birth_location_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('death_date')}
        label={props.t('PersonModal.labels.deathDate')}
        onChange={props.onTextInputChange.bind(this, 'death_date')}
        required={props.isRequired('death_date')}
        value={props.item.death_date || ''}
      />
      <Form.Input
        error={props.isError('death_location_id')}
        label={props.t('PersonModal.labels.deathLocation')}
        required={props.isRequired('death_location_id')}
      >
        <AssociatedDropdown
          collectionName='locations'
          modal={{
            component: LocationModal,
            props: {
              required: ['name']
            },
            onSave: (location) => Locations.save(location).then(({ data }) => data.location)
          }}
          onSearch={(search) => Locations.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'death_location_id', 'death_location')}
          renderOption={Location.toDropdownOption.bind(this)}
          searchQuery={props.item.death_location && props.item.death_location.name}
          value={props.item.death_location_id}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(PersonModal);
