// @flow

import uuid from 'uuid';

/**
 * Class for handling transforming Javascript file objects.
 */
class File {
  FILE_SIZES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  /**
   * Converts the passed file to an attachment object.
   *
   * @param file
   *
   * @returns Attachment
   */
  toAttachment(file) {
    return {
      uid: uuid.v4(),
      name: file.name,
      size: file.size,
      file
    };
  }

  /**
   * Converts the passed file list into a plain Javascript array.
   *
   * @param fileList
   *
   * @returns {[]}
   */
  toArray(fileList) {
    const array = [];

    for (let i = 0; i < fileList.length; i += 1) {
      array.push(fileList.item(i));
    }

    return array;
  }

  /**
   * Converts the passed file bytes into a human-readable format.
   *
   * @param bytes
   *
   * @returns {string}
   */
  toReadableSize(bytes) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / (1024 ** i)).toFixed(2) * 1} ${this.FILE_SIZES[i]}`;
  }
}

export default new File();
