// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import PageModal from '../components/PageModal';
import PagesService from '../services/Pages';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';

import type { Translateable } from '../types/Translateable';

const Pages = (props: Translateable) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'delete'
    }]}
    className='pages'
    collectionName='pages'
    columns={[{
      name: 'title',
      label: props.t('Pages.columns.title'),
      sortable: true
    }]}
    modal={{
      component: PageModal,
      props: {
        required: ['title', 'category']
      }
    }}
    onDelete={(page) => PagesService.delete(page)}
    onLoad={(params) => PagesService.fetchAll(params)}
    onSave={(page) => PagesService.save(page)}
    perPageOptions={[10, 25, 50, 100]}
    session={{
      key: 'pages',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(Pages);
