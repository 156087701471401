// @flow

import React, { type Node } from 'react';
import { withRouter } from 'react-router-dom';
import CookiePolicyBanner from './CookiePolicyBanner';
import Footer from './Footer';
import Navbar from './Navbar';

type Props = {
  children: Node,
  location: {
    pathname: string
  }
};

const Layout = (props: Props) => (
  <>
    <Navbar />
    { props.children }
    <Footer />
    <CookiePolicyBanner
      id={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
      location={props.location && props.location.pathname}
      storageKey={process.env.REACT_APP_GOOGLE_ANALYTICS_STORAGE_KEY}
    />
  </>
);

export default withRouter(Layout);
