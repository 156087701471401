// @flow

import React, { Component } from 'react';
import {
  GoogleMap,
  GooglePlacesSearch
} from '@performant-software/semantic-components';
import {
  Button,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import _ from 'underscore';
import Numbers from '../utils/Numbers';
import TranslateField from './TranslateField';
import './LocationModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Location } from '../types/Location';

type Props = EditContainerProps & { item: Location };
type State = {};

const DECIMAL_FORMAT = 5;
const KEY_ENTER = 13;

class LocationModal extends Component<Props, State> {
  /**
   * Returns the current coordinates for the location. Undefined is returned if a location has not been selected.
   *
   * @returns {undefined|*}
   */
  getCoordinates() {
    if (!(this.props.item.lat && this.props.item.lng)) {
      return undefined;
    }

    return _.pick(this.props.item, 'lat', 'lng');
  }

  /**
   * Clears the name, lat, and lng properties on the location.
   */
  onClearTextInput() {
    this.props.onSetState({ name: '', lat: null, lng: null });
  }

  /**
   * Renders the LocationModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        className='location-modal'
        open
        noValidate
        size='small'
      >
        <Modal.Header>
          <Grid
            columns={2}
            verticalAlign='middle'
          >
            <Grid.Column
              textAlign='left'
            >
              <Header
                content={this.props.item.id
                  ? this.props.t('LocationModal.title.editLocation')
                  : this.props.t('LocationModal.title.addLocation')}
              />
            </Grid.Column>
            <Grid.Column
              textAlign='right'
            >
              { this.renderCoordinates() }
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <GooglePlacesSearch
            containerElement={<Form.Field />}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onLocationSelection={this.props.onSetState.bind(this)}
          >
            <TranslateField
              inputField={{
                autoFocus: true,
                error: this.props.isError('name'),
                label: this.props.t('LocationModal.labels.name'),
                onChange: this.props.onTextInputChange.bind(this, 'name'),
                onKeyDown: (e) => e.keyCode === KEY_ENTER && e.preventDefault(),
                required: this.props.isRequired('name'),
                value: this.props.item.name || ''
              }}
              translationField={{
                error: this.props.isError('arabic_name'),
                label: this.props.t('LocationModal.labels.arabicName'),
                layout: Arabic,
                onChange: this.props.onTextInputChange.bind(this, 'arabic_name'),
                required: this.props.isRequired('arabic_name'),
                value: this.props.item.arabic_name || ''
              }}
            >
              <input
                className='arabic'
                dir='rtl'
                type='text'
              />
            </TranslateField>
          </GooglePlacesSearch>
          <GoogleMap
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onDragEnd={({ lat, lng }) => this.props.onSetState({ lat, lng })}
            position={{ lat: this.props.item.lat, lng: this.props.item.lng }}
          />
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }

  /**
   * Renders the latitude, longitude, and clear button.
   *
   * @returns {null|*}
   */
  renderCoordinates() {
    if (!(this.props.item.lat && this.props.item.lng)) {
      return null;
    }

    const latitude = Numbers.round(this.props.item.lat, DECIMAL_FORMAT);
    const longitude = Numbers.round(this.props.item.lng, DECIMAL_FORMAT);

    return (
      <>
        <span
          className='coordinates'
        >
          { `${latitude}, ${longitude}` }
        </span>
        <Button
          basic
          content={this.props.t('Common.buttons.clear')}
          icon='times'
          onClick={this.onClearTextInput.bind(this)}
          type='button'
        />
      </>
    );
  }
}

export default withTranslation()(LocationModal);
