// @flow

import React, { Component, createRef } from 'react';
import { EmbeddedList, FileUpload, PhotoViewer } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import File from '../transforms/File';
import './AttachmentsList.css';

import type { Attachment } from '../types/Attachment';

type Props = {
  attachments: Array<Attachment>,
  onDelete?: (attachment: Attachment) => void,
  onFilesAdded?: (files: Array<File>) => void,
  t: (key: string) => string,
  viewOnly?: boolean,
};

type State = {
  attachment: ?Attachment,
  audioPlayer: ?Audio,
  image: ?string,
  links: { [number | string]: string },
  modalPhoto: boolean
};

const AUDIO_FILE = /(.*?)\.(mp3|wav)$/i;
const IMAGE_FILE = /(.*?)\.(jpg|jpeg|gif|png)$/i;

const AUDIO_TYPE = 'audio/wav';

const MAX_FILE_SIZE = 10485760; // 10 MB
const FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'mp3',
  'wav'
];

class AttachmentsList extends Component<Props, State> {
  linkRefs: any;

  /**
   * Constructs a new AttachmentsList component.
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      attachment: null,
      audioPlayer: null,
      image: null,
      links: {},
      modalPhoto: false,
    };

    this.linkRefs = {};
  }

  /**
   * Returns the ID for the passed attachment.
   *
   * @param attachment
   *
   * @returns {*}
   */
  getAttachmentId(attachment: Attachment) {
    return attachment.uid || attachment.id;
  }

  /**
   * Returns the URL for the passed attachment.
   *
   * @param attachment
   *
   * @returns {*}
   */
  getAttachmentUrl(attachment: Attachment) {
    return attachment.file ? URL.createObjectURL(attachment.file) : attachment.url;
  }

  /**
   * Returns the download URL for the passed attachment.
   *
   * @param attachment
   *
   * @returns {Promise<unknown>|Promise<R>|Promise<string>}
   */
  getDownloadUrl(attachment: Attachment) {
    if (attachment.id) {
      return fetch(attachment.url)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));
    }

    return Promise.resolve(this.getAttachmentUrl(attachment));
  }

  /**
   * Closes the photo viewer.
   */
  onClosePhotoViewer() {
    this.setState({ modalPhoto: false, image: null });
  }

  /**
   * Downloads the passed attachment and sets the URL on the state.
   *
   * @param attachment
   *
   * @returns {Promise<void>}
   */
  async onDownloadAttachment(attachment: Attachment) {
    const id = this.getAttachmentId(attachment);
    const url = await this.getDownloadUrl(attachment);

    this.setState((state) => ({
      links: { ...state.links, [id]: url }
    }), () => {
      this.linkRefs[id].current.click();
    });
  }

  /**
   * Opens the photo viewer for the passed attachment.
   *
   * @param attachment
   */
  onOpenPhotoViewer(attachment: Attachment) {
    const image = this.getAttachmentUrl(attachment);
    this.setState({ image, modalPhoto: true });
  }

  /**
   * Plays the audio file represented by the passed attachment.
   *
   * @param attachment
   */
  onPlayAudio(attachment: Attachment) {
    this.onStopAudio();

    const audioPlayer = new Audio(this.getAttachmentUrl(attachment));
    audioPlayer.type = AUDIO_TYPE;

    this.setState({ attachment, audioPlayer }, () => {
      this.state.audioPlayer.play();
    });
  }

  /**
   * Stops the currently playing audio file.
   */
  onStopAudio() {
    if (this.state.audioPlayer) {
      this.state.audioPlayer.pause();
    }

    this.setState({ attachment: null, audioPlayer: null });
  }

  /**
   * Renders the AttachmentsList component.
   *
   * @returns {*}
   */
  render() {
    const adminActions = [
      {
        name: 'download',
        render: this.renderDownloadButton.bind(this)
      },
      {
        name: 'delete',
      }
    ];

    return (
      <div
        className='attachments-list'
      >
        <EmbeddedList
          actions={[{
            name: 'view',
            icon: 'picture',
            accept: (attachment) => !!attachment.name.match(IMAGE_FILE),
            onClick: this.onOpenPhotoViewer.bind(this)
          }, {
            name: 'play',
            icon: 'play circle outline',
            accept: (attachment) => !!attachment.name.match(AUDIO_FILE) && attachment !== this.state.attachment,
            onClick: this.onPlayAudio.bind(this)
          }, {
            name: 'stop',
            icon: 'stop circle outline',
            accept: (attachment) => !!attachment.name.match(AUDIO_FILE) && attachment === this.state.attachment,
            onClick: this.onStopAudio.bind(this)
          },
          ...(this.props.viewOnly ? [] : adminActions)
          ]}
          columns={[{
            name: 'name',
            label: this.props.t('AttachmentsList.columns.name')
          }, {
            name: 'size',
            label: this.props.t('AttachmentsList.columns.size'),
            resolve: (attachment) => File.toReadableSize(attachment.size)
          }]}
          items={this.props.attachments}
          onDelete={!this.props.viewOnly ? this.props.onDelete.bind(this) : null}
        />
        {!this.props.viewOnly
          && (
          <FileUpload
            fileTypes={FILE_TYPES}
            maxSize={MAX_FILE_SIZE}
            onFilesAdded={this.props.onFilesAdded.bind(this)}
          />
          )}
        <PhotoViewer
          image={this.state.image}
          onClose={this.onClosePhotoViewer.bind(this)}
          open={this.state.modalPhoto}
          size='large'
        />
      </div>
    );
  }

  /**
   * Renders the download button for the passed attachment.
   *
   * @param attachment
   * @param index
   *
   * @returns {*}
   */
  renderDownloadButton(attachment: Attachment, index: number) {
    const id = this.getAttachmentId(attachment);

    const linkRef = createRef();
    this.linkRefs[id] = linkRef;

    return (
      <a
        download={attachment.name}
        href={this.state.links[id]}
        key={index}
        ref={linkRef}
      >
        <Button
          basic
          compact
          icon='cloud download'
          onClick={this.onDownloadAttachment.bind(this, attachment)}
        />
      </a>
    );
  }
}

export default withTranslation()(AttachmentsList);
