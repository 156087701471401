// @flow

import React from 'react';
import { ColorButton } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import PrincipleModal from '../components/PrincipleModal';
import PrinciplesService from '../services/Principles';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';

type Props = {
  t: (key: string) => string
};

const Principles = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    collectionName='principles'
    columns={[{
      name: 'name',
      className: 'arabic',
      label: props.t('Principles.columns.name'),
      sortable: true
    }, {
      name: 'rule',
      className: 'arabic',
      label: props.t('Principles.columns.rule'),
      sortable: true
    }, {
      name: 'color',
      label: props.t('Principles.columns.color'),
      render: (principle) => <ColorButton color={principle.color} />,
      sortable: true
    }]}
    modal={{
      component: PrincipleModal,
      props: {
        defaults: {
          color: '#fff'
        },
        required: ['name'],
      }
    }}
    onDelete={(principle) => PrinciplesService.delete(principle)}
    onLoad={(params) => PrinciplesService.fetchAll(params)}
    onSave={(principle) => PrinciplesService.save(principle)}
    perPageOptions={[10, 25, 50, 100]}
    session={{
      key: 'principles',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(Principles);
