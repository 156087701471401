// @flow

import { BaseService } from '@performant-software/shared-components';

/**
 * Service class for all verse based API calls.
 */
class Verses extends BaseService {
  /**
   * Overrides the create API call. This function is not implemented for this class.
   *
   * @param item
   */
  // eslint-disable-next-line no-unused-vars
  create(item: any) {
    // Not implemented
  }

  /**
   * Returns the people base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/verses';
  }

  /**
   * Overrides the save API call. This function is not implemented for this class.
   * @param item
   */
  // eslint-disable-next-line no-unused-vars
  save(item: any) {
    // Not implemented
  }

  /**
   * Overrides the update API call. This function is not implemented for this class.
   *
   * @param item
   */
  // eslint-disable-next-line no-unused-vars
  update(item: any) {
    // Not implemented
  }
}

export default new Verses();
