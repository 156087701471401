// @flow

import React, { Component } from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Button, Portal, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import Principles from '../services/Principles';
import PrincipleTransform from '../transforms/Principle';
import type { Annotation } from '../types/Annotation';
import type { PrincipleMembership } from '../types/PrincipleMembership';
import type { Word } from '../types/Word';
import { getAnchorProps } from '../utils/Words';

import './PrincipleMembershipPortal.css';

type Props = {
  onClearButton: () => void,
  onDeleteAnnotation: (principleMembership: PrincipleMembership) => void,
  onSaveAnnotation: (annotation: Annotation) => void,
  principleMemberships: ?Array<PrincipleMembership>,
  renderSelectedWord: (word: Word) => string,
  savingAnnotation: boolean,
  t: (key: string) => string,
  words: Array<Word>,
};

type State = {
  annotation: any,
};

const SPACE = ' ';

class PrincipleMembershipPortal extends Component<Props, State> {
  /**
   * Constructs a new PrincipleMembershipPortal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      annotation: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.annotation && prevProps.words !== this.props.words) {
      this.setState({ annotation: null });
    }
  }

  componentDidMount() {
    this.setState({ annotation: null });
  }

  componentWillUnmount() {
    this.setState({ annotation: null });
  }

  /**
   * Sets the associated ID and object.
   *
   * @param record
   */
  onPrincipleInputChange(record: any = {}) {
    this.setState((state) => ({
      annotation: {
        ...state.annotation,
        principle_membership_id: record.id || '',
        principle_membership: record || {},
      },
    }));
  }

  /**
   * Sets the principle on the state.
   *
   * @param principle
   */
  onPrincipleSelection(principle) {
    this.onPrincipleInputChange({
      principle_id: principle && principle.id,
      principle,
      ...getAnchorProps(this.props.words),
      _destroy: !principle,
    });
  }

  /**
   *
   * Renders the passed principle
   *
   * @param principle
   *
   * @returns {*}
   */
  renderSelectedWordPrinciple(principleMembership) {
    return (
      <div key={principleMembership.principle.id} className='selected-principle-container'>
        <div className='selected-principle-delete'>
          <Button
            basic
            circular
            color='red'
            disabled={this.props.savingAnnotation}
            icon='x'
            onClick={() => {
              this.props.onDeleteAnnotation(principleMembership);
            }}
            size='mini'
          />
        </div>
        <div className='selected-principle-name'>{principleMembership.principle.name}</div>
      </div>
    );
  }

  /**
   *
   * Renders the field to add a principle
   *
   * @returns {*}
   */
  renderAddPrincipleField() {
    return (
      <div key='add-principle' className='selected-principle-container'>
        <Button
          basic
          circular
          color='green'
          disabled={this.props.savingAnnotation || !(
            this.state.annotation
            && this.state.annotation.principle_membership
            && this.state.annotation.principle_membership.principle_id
          )}
          icon='plus'
          onClick={() => this.props.onSaveAnnotation(this.state.annotation)}
          size='mini'
        />
        <AssociatedDropdown
          className='principle-selection-dropdown'
          collectionName='principles'
          onSearch={(search) => Principles.fetchAll({ search, per_page: 25 })}
          onSelection={this.onPrincipleSelection.bind(this)}
          renderOption={PrincipleTransform.toDropdownOption.bind(this)}
          placeholder={this.props.t('Text.selectPrinciple')}
          required
          upward
          value={
            this.state.annotation
            && this.state.annotation.principle_membership
            && this.state.annotation.principle_membership.principle_id
          }
        />
      </div>
    );
  }


  /**
   * Renders the Principle Membership Portal.
   *
   * @returns {*}
   */
  render() {
    const {
      onClearButton,
      principleMemberships,
      renderSelectedWord,
      t,
      words,
    } = this.props;

    if (!words.length) {
      return null;
    }

    const selectedWordPrinciples = principleMemberships
      ? principleMemberships
        .filter(
          (pm) => pm.start_word_id === _.first(_.pluck(words, 'id'))
              && pm.offset === words.length - 1
        )
        .filter(
          (pm, idx, self) => self.findIndex((pm_) => pm_.id === pm.id) === idx
        )
        .map((pm) => ({
          principle: pm.principle,
          annotation_id: pm.annotation_id,
        }))
      : [];

    return (
      <>
        <Portal open={!!words.length}>
          <Segment className='selection-button-container' textAlign='center'>
            <div className='arabic-selection'>
              {words.map(renderSelectedWord.bind(this)).join(SPACE)}
            </div>
            <div className='principle-memberships'>
              <div className='principle-header'>
                {this.props.t('Entry.menu.principles')}
              </div>
              {selectedWordPrinciples
                && selectedWordPrinciples.length > 0
                && selectedWordPrinciples.map(
                  this.renderSelectedWordPrinciple.bind(this)
                )}
              {this.renderAddPrincipleField()}
            </div>
            <Button
              className='selection-button'
              color='red'
              content={t('Text.buttons.cancel')}
              disabled={!words.length || this.props.savingAnnotation}
              onClick={onClearButton.bind(this)}
            />
          </Segment>
        </Portal>
      </>
    );
  }
}

export default withTranslation()(PrincipleMembershipPortal);
