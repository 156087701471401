// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { LoginModal } from '@performant-software/semantic-components';
import { useEditContainer } from '@performant-software/shared-components';
import { withTranslation } from 'react-i18next';
import { Link, withRouter, type RouteComponentProps } from 'react-router-dom';
import Authentication from '../services/Authentication';
import PagesService from '../services/Pages';
import Session from '../services/Session';
import './Navbar.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Translateable } from '../types/Translateable';

type Props = EditContainerProps & RouteComponentProps & Translateable & {
    item: {
      password: string,
      username: string
    }
  };

const Navbar = (props: Props) => {
  const [toggled, setToggled] = useState(false);
  const [pages, setPages] = useState([]);

  /**
   * Fetch the pages from the three main categories
   * to feature in the navbar.
   */
  useEffect(() => {
    PagesService
      .fetchAll({ category: ['about', 'encyclopedia', 'resources'] })
      .then(({ data }) => setPages(data.pages));
  }, []);

  /**
   * Calls the login API and navigates the user to the admin view.
   *
   * @type {(function(): void)|*}
   */
  const onLogin = useCallback(() => {
    const { username, password } = props.item;

    Authentication
      .login(username, password)
      .then((response) => {
        Session.create(response);
        props.onSetState({ showModal: false });
        props.history.push('/entry');
      })
      .catch(() => {
        props.onSetState({ loginFailed: true });
        Session.destroy();
      });
  }, [props.history, props.item, props.onSetState]);

  return (
    <div className='nav-container' id='navbar'>
      <div className='logo-container logo-container-index'>
        <Link className='flex-container' to='/'>
          <h1 className='logo'>E</h1>
          <div>
            <p className='subtitle-small index-logo-v'>v</p>
            <hr />
          </div>
          <h1 className='logo'>Q</h1>
        </Link>
      </div>
      <nav className='navbar'>
        <input className='menu-btn' type='checkbox' id='menu-btn' checked={toggled} />
        <button
          className='menu-icon'
          onClick={() => setToggled(!toggled)}
          type='button'
        >
          <span className='navicon' />
        </button>
        <ul className='nav-menu'>
          <li className='category-name'>
            <Link to='/'>
              {props.t('Navbar.labels.encyclopedia')}
            </Link>
            <ul className='dropdown'>
              {pages.filter((p) => p.category_slug === 'encyclopedia').map((page) => (
                <li className='page-link' key={page.slug}>
                  <Link to={`/pages/${page.category_slug}/${page.slug}`}>
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className='category-name'>
            <span className='dropdown-label'>{props.t('Navbar.labels.resources')}</span>
            <ul className='dropdown'>
              <li className='page-link'>
                <Link to='/pages/news'>
                  {props.t('Navbar.labels.news')}
                </Link>
              </li>
              <li className='page-link'>
                <Link to='/pages/videos'>
                  {props.t('Navbar.labels.videos')}
                </Link>
              </li>
              {pages.filter((p) => p.category_slug === 'resources').map((page) => (
                <li className='page-link' key={page.slug}>
                  <Link to={`/pages/${page.category_slug}/${page.slug}`}>
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className='category-name'>
            <span className='dropdown-label'>{props.t('Navbar.labels.about')}</span>
            <ul className='dropdown'>
              {pages.filter((p) => p.category_slug === 'about').map((page) => (
                <li className='page-link' key={page.slug}>
                  <Link to={`/pages/${page.category_slug}/${page.slug}`}>
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className='category-name page-link-mobile'>
            <LoginModal
              disabled={!(props.item.username && props.item.password)}
              loginFailed={props.item.loginFailed}
              onClose={() => props.onSetState({ showModal: false })}
              onLogin={onLogin}
              onPasswordChange={(e) => props.onTextInputChange('password', e, { value: e.target.value })}
              onUsernameChange={(e) => props.onTextInputChange('username', e, { value: e.target.value })}
              open={props.item.showModal}
              trigger={(
                <button
                  className='login-button'
                  onClick={() => {
                    if (Session.isAuthenticated()) {
                      props.history.push('/entry');
                    } else {
                      props.onSetState({ showModal: true });
                    }
                  }}
                  size='medium'
                  type='button'
                >
                  { Session.isAuthenticated() ? props.t('Home.adminView') : props.t('Home.login') }
                </button>
              )}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default withTranslation()(withRouter(useEditContainer(Navbar)));
