// @flow

import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Container,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import Pages from '../services/Pages';
import RichTextArea from '../components/RichTextArea';
import './Page.css';

const Page = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();

  const { categorySlug, pageSlug } = useParams();

  /**
   * Fetch the static page based on the route.
   */
  useEffect(() => {
    setLoading(true);

    Pages
      .fetchOne(pageSlug)
      .then(({ data }) => setPage(data.page))
      .finally(() => setLoading(false));
  }, [categorySlug, pageSlug]);

  return (
    <Container className='page-component'>
      { loading && (
        <Dimmer active inverted page>
          <Loader />
        </Dimmer>
      )}
      { page && (
        <>
          <h1 className='page-header'>
            {page.title}
          </h1>
          <RichTextArea
            readOnly
            theme='bubble'
            value={page.content}
          />
        </>
      )}
    </Container>
  );
};

export default withRouter(Page);
