// @flow

import React from 'react';
import AnnotateableDeleteModal from '../components/AnnotateableDeleteModal';

import type { Annotateable } from '../types/Annotateable';

type Props = {
  onCancel: () => void,
  onConfirm: () => void,
  selectedItem: Annotateable
};

const renderDeleteModal = ({ onCancel, onConfirm, selectedItem }: Props) => (
  <AnnotateableDeleteModal
    count={selectedItem.annotations_count}
    onCancel={onCancel}
    onConfirm={onConfirm}
  />
);

export {
  renderDeleteModal
};
