// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Label, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import './TagsList.css';
import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  items: Array<any>,
  onDelete?: (item: any) => void,
  renderItem: (item: any) => Element<any>
};

const TagsList = (props: Props) => (
  <Segment
    className='tags-list'
  >
    { !_.isEmpty(props.items) && (
      <Label.Group>
        { _.map(props.items, (item, index) => (
          <Label
            key={index}
          >
            { props.renderItem(item) }
            { props.onDelete && (
              <Icon
                name='delete'
                onClick={props.onDelete.bind(this, item)}
              />
            )}

          </Label>
        ))}
      </Label.Group>
    )}
    { _.isEmpty(props.items) && (
      <div
        className='empty-list'
      >
        { props.t('TagsList.labels.noRecords') }
      </div>
    )}
  </Segment>
);

TagsList.defaultProps = {
  onDelete: undefined
};

export default withTranslation()(TagsList);
