// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import ErrorUtils from '../utils/Errors';
import PersonModal from '../components/PersonModal';
import PeopleService from '../services/People';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';

type Props = {
  t: (key: string) => string
};

const People = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    collectionName='people'
    columns={[{
      name: 'name',
      label: props.t('People.columns.name'),
      sortable: true
    }, {
      name: 'abbreviation',
      label: props.t('People.columns.abbreviation'),
      sortable: true
    }, {
      className: 'arabic',
      name: 'arabic_name',
      label: props.t('People.columns.arabicName'),
      sortable: true
    }, {
      className: 'arabic',
      name: 'arabic_abbreviation',
      label: props.t('People.columns.arabicAbbreviation'),
      sortable: true
    }, {
      name: 'death_date',
      label: props.t('People.columns.deathDate'),
      sortable: true
    }, {
      name: 'death_locations.name',
      label: props.t('People.columns.deathLocation'),
      resolve: (person) => person.death_location && person.death_location.name,
      sortable: true
    }]}
    modal={{
      component: PersonModal,
      props: {
        required: ['name', 'abbreviation']
      }
    }}
    onDelete={(person) => PeopleService.delete(person)}
    onLoad={(params) => PeopleService.fetchAll(params)}
    onSave={(person) => PeopleService.save(person)}
    perPageOptions={[10, 25, 50, 100]}
    resolveErrors={ErrorUtils.resolveErrors.bind(this)}
    session={{
      key: 'people',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(People);
