// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import ManualModal from '../components/ManualModal';
import ManualsService from '../services/Manuals';
import Manual from '../transforms/Manual';
import { renderDeleteModal } from '../utils/Annotations';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';

type Props = {
  t: (key: string) => string
};

const Manuals = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    collectionName='manuals'
    columns={[{
      name: 'name',
      label: props.t('Manuals.columns.name'),
      sortable: true
    }, {
      className: 'arabic',
      name: 'arabic_name',
      label: props.t('Manuals.columns.arabicName'),
      sortable: true
    }, {
      name: 'publication_date',
      label: props.t('Manuals.columns.publicationDate'),
      sortable: true
    }, {
      name: 'publication_location.name',
      label: props.t('Manuals.columns.publicationLocation'),
      resolve: (manual) => manual.publication_location && manual.publication_location.name,
      sortable: true
    }]}
    modal={{
      component: ManualModal,
      props: {
        required: ['name']
      }
    }}
    onCopy={(manual) => Manual.toCopy(manual)}
    onDelete={(manual) => ManualsService.delete(manual)}
    onLoad={(params) => ManualsService.fetchAll(params)}
    onSave={(manual) => ManualsService.save(manual)}
    perPageOptions={[10, 25, 50, 100]}
    renderDeleteModal={renderDeleteModal.bind(this)}
    session={{
      key: 'manuals',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(Manuals);
