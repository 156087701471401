// @flow

import React, { useCallback, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Menu,
  Modal,
  Ref
} from 'semantic-ui-react';
import AnnotationsList from './AnnotationsList';
import ScrollViewContext from '../contexts/ScrollViewContext';
import VariantsList from './VariantsList';
import './TableViewModal.css';

import type { Translateable } from '../types/Translateable';
import type { Word } from '../types/Word';

const VARIANT_TAB_INDEX = 0;
const ANNOTATION_TAB_INDEX = 1;

type Props = Translateable & {
  filterParams: any,
  onClose: (filters: any, words: ?Array<Word>) => void,
  selectedWord: Word
};

const TableViewModal = (props: Props) => {
  const [filters, setFilters] = useState(props.filterParams);
  const [tabIndex, setTabIndex] = useState(0);
  const [words, setWords] = useState(props.selectedWord ? [props.selectedWord] : undefined);

  const modalRef = useRef();

  /**
   * Calls the onClose prop.
   *
   * @type {function(): *}
   */
  const onClose = useCallback(() => props.onClose(filters, words), [filters, words, props.onClose]);

  /**
   * Sets the words to null if a word_id is not provided.
   *
   * @type {(function(*): void)|*}
   */
  const onFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);

    if (!newFilters.word_id) {
      setWords(null);
    }
  }, []);

  /**
   * Scrolls to the top of the modal.
   *
   * @type {(function(): void)|*}
   */
  const scrollToTop = useCallback(() => {
    if (modalRef && modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [modalRef]);

  return (
    <Ref
      innerRef={modalRef}
    >
      <ScrollViewContext.Provider
        value={{ scrollToTop }}
      >
        <Modal
          className='modal-container'
          closeOnDimmerClick={false}
          dimmer
          onClose={onClose}
          open
          size='large'
        >
          <Menu
            tabular
          >
            <Menu.Item
              name={props.t('TableViewModal.tabs.variants')}
              active={tabIndex === VARIANT_TAB_INDEX}
              onClick={() => setTabIndex(VARIANT_TAB_INDEX)}
            />
            <Menu.Item
              name={props.t('TableViewModal.tabs.annotations')}
              active={tabIndex === ANNOTATION_TAB_INDEX}
              onClick={() => setTabIndex(ANNOTATION_TAB_INDEX)}
            />
            <Menu.Menu
              position='right'
            >
              <Menu.Item>
                <Button
                  onClick={onClose}
                  primary
                >
                  { props.t('TableViewModal.backButton') }
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          { tabIndex === VARIANT_TAB_INDEX && (
            <VariantsList
              filters={filters}
              onFilterChange={onFilterChange}
              publicView
              words={words}
            />
          )}
          { tabIndex === ANNOTATION_TAB_INDEX && (
            <AnnotationsList
              filters={filters}
              onFilterChange={onFilterChange}
              publicView
              words={words}
            />
          )}
        </Modal>
      </ScrollViewContext.Provider>
    </Ref>
  );
};

export default withTranslation()(TableViewModal);
