// @flow

import { BaseService } from '@performant-software/shared-components';

/**
 * Services class responsible for all authentication based API calls.
 */
class Authentication extends BaseService {
  /**
   * Returns the authentication base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/auth';
  }

  /**
   * Calls the /api/auth/sign_in end point with the passed email and password.
   *
   * @param email
   * @param password
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  login(email: string, password: string) {
    const axios = this.getAxios();
    return axios.post(`${this.getBaseUrl()}/sign_in`, { email, password });
  }

  /**
   * Calls the api/auth/sign_out end point.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  logout() {
    const axios = this.getAxios();
    return axios.delete(`${this.getBaseUrl()}/sign_out`);
  }
}

export default new Authentication();
