// @flow

import React, { useState } from 'react';
import { ColorButton, ListTable } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Popup,
  Segment
} from 'semantic-ui-react';
import _, { isEmpty } from 'underscore';
import PrinciplesService from '../services/Principles';
import StatusChip from './StatusChip';
import TableViewModal from './TableViewModal';
import VariantStatuses from '../resources/VariantStatuses.json';
import './TextSidebar.css';

import type { Attachment } from '../types/Attachment';
import type { Variant } from '../types/Variant';
import type { Word } from '../types/Word';

const AUDIO_FILE = /(.*?)\.(mp3|wa'v)$/i;
const AUDIO_TYPE = 'audio/wav';

const Modes = {
  principles: 'principles',
  variants: 'variants'
};

type Props = {
  filterParams: any,
  isMobile: boolean,
  mobileOpen: boolean,
  mode: string,
  onStatusFilterSelection: (e: Event, response: {}) => void,
  onUpdateSidebar: (filterParams: any, words: ?Array<Word>) => void,
  onVariantWordClick: (startWordId: number, variantId: ?number) => void,
  selectedStatusFilter: ?string,
  selectedVariant: ?Variant,
  selectedWord: ?Word,
  t: (key: string, props: any) => string,
  variantsList: Array<Variant>,
  width: number
};

const TextSidebar = (props: Props) => {
  const {
    width,
    onStatusFilterSelection,
    selectedStatusFilter,
    selectedWord,
    selectedVariant,
    mobileOpen,
    isMobile
  } = props;

  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const noStatusOption = {
    key: 'no-status',
    value: null,
    text: 'No Status',
    abbreviation: 'NS',
    color: 'grey',
    order: '9999'
  };

  const statuses = [...VariantStatuses, noStatusOption];
  const vList = !isEmpty(props.variantsList) ? props.variantsList : [];
  const vListWithOrderNum = vList.map((v) => ({ ...v, order: statuses.find((s) => v.status === s.value).order }));
  const variantsList = _.sortBy(vListWithOrderNum, 'order');

  const handleModalClose = (filters, words) => {
    setIsModalOpen(false);
    props.onUpdateSidebar(filters, words);
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleVariantWordClick = (startWordId, variantId) => {
    props.onVariantWordClick(startWordId, variantId);
  };
    /**
     * Returns the URL for the passed attachment.
     *
     * @param attachment
     *
     * @returns {*}
     */
  const getAttachmentUrl = (attachment: Attachment) => (attachment.file
    ? URL.createObjectURL(attachment.file)
    : attachment.url);
  /**
     * Stops the currently playing audio file.
     */
  const onStopAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
    }
    setAudioPlayer(null);
  };

  /**
     * Plays the audio file represented by the passed attachment.
     *
     * @param attachment
     */
  const onPlayAudio = (attachment: Attachment) => {
    onStopAudio();
    const newAudioPlayer = new Audio(getAttachmentUrl(attachment));
    newAudioPlayer.type = AUDIO_TYPE;
    setAudioPlayer(newAudioPlayer);
    newAudioPlayer.play();
    // stops audio player after it completes playing
    newAudioPlayer.onloadedmetadata = () => {
      setTimeout(onStopAudio, newAudioPlayer.duration * 1000);
    };
  };

  const VariantOptionLabel = ({ status }) => (
    <div style={{ display: 'flex', width: 'fit-content' }}>
      <div style={{ width: 35, textAlign: 'center' }}>
        <StatusChip status={status} disablePopup />
      </div>
      <p style={{ marginLeft: 5 }}>{status.text}</p>
    </div>
  );

  const variantStatusOptions = statuses.map((s) => ({
    ...s,
    content: <Dropdown.Item key={`variant-${s.value}`}><VariantOptionLabel status={s} /></Dropdown.Item>

  }));

  const numbOfCountDigits = variantsList ? variantsList.length.toString().length : 0;
  const selectedVariantId = selectedVariant ? selectedVariant.id : null;
  const filtersPresent = !!Object.values(props.filterParams).find((p) => !isEmpty(p));

  const content = (
    <div className='sticky-content'>
      { props.mode === Modes.principles && (
      <ListTable
        buttons={[{
          as: Link,
          basic: true,
          content: props.t('TextSidebar.buttons.principles'),
          target: '_blank',
          to: '/pages/principles'
        }]}
        collectionName='principles'
        columns={[{
          name: 'name',
          className: 'arabic',
          label: props.t('Principles.columns.name')
        }, {
          name: 'rule',
          className: 'arabic',
          label: props.t('Principles.columns.rule')
        }, {
          name: 'color',
          label: props.t('Principles.columns.color'),
          render: (principle) => <ColorButton color={principle.color} />
        }]}
        defaultPerPage={15}
        onLoad={(params) => PrinciplesService.fetchAll(params)}
        searchable={false}
      />
      )}
      { props.mode === Modes.variants && (
      <>
        <Header as='h4'>
          {props.t('TextSidebar.filterTagsHeader')}
        </Header>
        <Dropdown
          placeholder={props.t('TextSidebar.selectVariantStatus')}
          fluid
          selection
          multiple
          options={variantStatusOptions}
          renderLabel={(item) => <VariantOptionLabel status={item} />}
          value={selectedStatusFilter === '' ? [] : [selectedStatusFilter]}
          onChange={onStatusFilterSelection}
          clearable
          closeOnChange
          search
        />
        <Header as='h4'>
          {props.t('TextSidebar.variants')}
        </Header>
        <div>
          <Popup
            content={props.t('TextSidebar.filterButtonHelperText')}
            position='left center'
            size='tiny'
            trigger={(
              <Button
                compact
                icon
                basic={!filtersPresent}
                onClick={handleModalOpen}
              >
                <Icon name='table' />
                <Icon name='filter' />
              </Button>
                    )}
          />
          <Popup
            content={props.t('TextSidebar.clearFilters')}
            position='left center'
            size='tiny'
            trigger={(
              <Button
                basic
                color='red'
                compact
                icon='trash'
                onClick={props.onUpdateSidebar.bind(this, {}, null)}
              />
                    )}
          />
        </div>
        { isModalOpen && (
        <TableViewModal
          filterParams={props.filterParams}
          onClose={handleModalClose}
          selectedWord={selectedWord}
        />
        )}
        <Segment
          className='variant-list-container'
          padded
        >
          {!_.isEmpty(variantsList)
            ? variantsList.map((v, idx) => {
              const variantStatusObj = VariantStatuses.find((vs) => vs.value === v.status);
              const color = variantStatusObj ? variantStatusObj.hex : '#767676';
              const audioAttachment = v.attachments.find((a) => a.name.match(AUDIO_FILE));
              return (
                <div className='variant-list-item' key={v.id}>
                  <button
                    className='arabic-button'
                    type='button'
                    onClick={() => handleVariantWordClick(v)}
                  >
                    <p
                      className={`arabic ${selectedVariantId === v.id ? 'selected' : ''}`}
                      style={{ color }}
                    >
                      {v.arabic_with_vowels}
                    </p>
                  </button>
                  <div className='status-chip-container'>
                    <StatusChip status={variantStatusObj} disablePopup={false} />
                  </div>
                  <Button
                    compact
                    size='small'
                    disabled={!audioAttachment}
                    basic
                    icon={`${audioPlayer ? 'stop' : 'play'} circle outline`}
                    onClick={audioPlayer ? onStopAudio : () => onPlayAudio(audioAttachment)}
                  />

                  <div style={{ width: `${numbOfCountDigits}ch`, marginLeft: 5 }}>
                    <p>
                      {idx + 1}
                    </p>
                  </div>
                </div>
              );
            })
            : (
              <div className='variant-list-item'>
                <p className='no-variants'>
                  {selectedWord ? props.t('TextSidebar.none') : props.t('TextSidebar.selectTextInstructions')}
                </p>
              </div>
            )}
        </Segment>
      </>
      )}
    </div>
  );

  if (isMobile) {
    return (
      <div className={`mobile-text-sidebar ${mobileOpen ? 'mobile-text-sidebar-open' : 'mobile-text-sidebar-closed'}`}>
        {content}
      </div>
    );
  }

  return (
    <Grid.Column
      width={width}
      className='text-sidebar'
      style={{
        position: 'relative',
        padding: '1.5rem 1rem 1rem'
      }}
    >
      {content}
    </Grid.Column>
  );
};

export default withTranslation()(TextSidebar);

export {
  Modes
};
