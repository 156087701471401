// @flow

import React from 'react';
import { withGoogleAnalytics } from '@performant-software/shared-components';
import { withTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import './CookiePolicyBanner.css';

import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  onAccept: () => void,
  onDecline: () => void
};

const CookiePolicyBanner = (props: Props) => (
  <Segment
    className='cookie-policy-banner'
    inverted
  >
    <div>
      { props.t('CookiePolicyBanner.content') }
    </div>
    <div>
      <Button
        content={props.t('CookiePolicyBanner.buttons.accept')}
        onClick={props.onAccept}
        primary
      />
      <Button
        content={props.t('CookiePolicyBanner.buttons.decline')}
        onClick={props.onDecline}
        inverted
      />
    </div>
  </Segment>
);

export default withTranslation()(withGoogleAnalytics(CookiePolicyBanner));
