// @flow

import React from 'react';
import Text from './Text';

const Home = () => (
  <Text
    publicView
  />
);

export default Home;
