// @flow

import _ from 'underscore';

import type { Page as PageType } from '../types/Page';

/**
 * Class for handling transforming page records.
 */
class Page {
  /**
   * Returns the page object to be sent to the server on POST/PUT requests.
   *
   * @param page
   *
   * @returns {*}
   */
  toPayload(page: PageType) {
    return {
      page: {
        ..._.pick(page, 'title', 'content'),
        category: _.map(page.category, (c) => _.pick(c, 'id', 'reference_code_id', '_destroy'))
      }
    };
  }
}

export default new Page();
