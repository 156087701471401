// @flow

import { BaseService } from '@performant-software/shared-components';
import VariantType from '../transforms/VariantType';

/**
 * Service class for all variant based API calls.
 */
class VariantTypes extends BaseService {
  /**
   * Returns the variants base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/variant_types';
  }

  /**
   * Returns the variants transform object.
   *
   * @returns {Variant}
   */
  getTransform() {
    return VariantType;
  }
}

export default new VariantTypes();
