// @flow

import _ from 'underscore';
import Footnote from './Footnote';
import Transmission from './Transmission';

import type { Annotation as AnnotationType } from '../types/Annotation';

/**
 * Class for handling transforming annotation records.
 */
class Annotation {
  /**
   * Returns the annotation object with the transmission transformed.
   *
   * @param annotation
   *
   * @returns {{transmission: {name: string}}}
   */
  toAnnotation(annotation: AnnotationType) {
    return {
      ...annotation,
      footnotes: Footnote.toFootnotes(annotation.footnotes),
      transmission: Transmission.toTransmission(annotation.transmission)
    };
  }

  /**
   * Returns a copy of the passed annotation.
   *
   * @param annotation
   *
   * @returns {{principle_membership: *}}
   */
  toCopy(annotation: AnnotationType) {
    return {
      ..._.omit(annotation, 'id'),
      principle_membership: _.omit(annotation.principle_membership, 'id')
    };
  }

  /**
   * Returns the annotation object to be sent to the server on POST/PUT requests.
   *
   * @param annotation
   *
   * @returns {*}
   */
  toPayload(annotation: AnnotationType) {
    return {
      annotation: {
        ..._.pick(annotation, [
          'id',
          'manual_id',
          'transmission_id',
          'variant_id',
          'page_number'
        ]),
        footnotes: Footnote.toPayload(_.filter(annotation.footnotes), (fn) => !fn._destroy),
        principle_membership: {
          ..._.pick(annotation.principle_membership, [
            'id',
            'principle_id',
            'start_word_id',
            'offset',
            '_destroy'
          ])
        }
      }
    };
  }
}

export default new Annotation();
