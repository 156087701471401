// @flow

import React, {
  useCallback,
  useEffect,
  useState,
  type Element
} from 'react';
import { Selectize } from '@performant-software/semantic-components';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';
import TagsList from './TagsList';

type Props = {
  collectionName: string,
  onChange: (items: any) => void,
  onLoad: (params: any) => Promise<any>,
  renderItem: (item: any) => Element<any>,
  title: string,
  value?: Array<number>,
};

/**
 * Renders the MultiSelect component. This component is used in conjunction with the Selectize component to render a
 * list of tags for selected items. The "Add" button can be used to open a Selectize to add/remove items.
 *
 * @param props
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const MultiSelect = (props: Props) => {
  const [items, setItems] = useState([]);
  const [selectize, setSelectize] = useState(false);

  /**
   * Saves the newly selected items on the state, closes the selectize modal, and calls the onChange prop with the
   * newly selected items.
   *
   * @type {(function(*=): void)|*}
   */
  const onSave = useCallback((newItems) => {
    setItems(newItems);
    setSelectize(false);
    props.onChange(newItems);
  }, [props.onChange]);

  /**
   * Clear the list of items if the value prop is not defined.
   */
  useEffect(() => {
    if (!props.value) {
      setItems([]);
    }
  }, [props.value]);

  /**
   * Loads the items for the passed defaultIds.
   */
  useEffect(() => {
    if (!_.isEmpty(props.value)) {
      props
        .onLoad({ ids: props.value })
        .then(({ data }) => onSave(data[props.collectionName]));
    }
  }, []);

  return (
    <>
      <h4>
        { props.title }
      </h4>
      <Button
        color='green'
        basic
        icon='plus'
        onClick={() => setSelectize(true)}
      />
      <TagsList
        items={items}
        onDelete={(item) => onSave(_.without(items, item))}
        renderItem={props.renderItem}
      />
      { selectize && (
        <Selectize
          collectionName={props.collectionName}
          onClose={() => setSelectize(false)}
          onLoad={props.onLoad}
          onSave={onSave}
          renderItem={props.renderItem}
          selectedItems={items}
          title={props.title}
        />
      )}
    </>
  );
};

MultiSelect.defaultProps = {
  value: undefined
};

export default MultiSelect;
