// @flow

import React from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import { Form, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import PersonModal from './PersonModal';
import People from '../services/People';
import PersonTransform from '../transforms/Person';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { TransmissionMembership } from '../types/TransmissionMembership';

type Props = EditContainerProps & { item: TransmissionMembership };

const TransmissionMembershipModal = (props: Props) => (
  <Modal
    as={Form}
    open
    noValidate
    size='small'
  >
    <Modal.Header content={props.item.id
      ? props.t('TransmissionMembershipModal.title.editMembership')
      : props.t('TransmissionMembershipModal.title.addMembership')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('person_id')}
        label={props.t('TransmissionMembershipModal.labels.person')}
        required={props.isRequired('person_id')}
      >
        <AssociatedDropdown
          collectionName='people'
          modal={{
            component: PersonModal,
            props: {
              required: ['name', 'abbreviation']
            },
            onSave: (person) => People.save(person).then(({ data }) => data.person)
          }}
          onSearch={(search) => People.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'person_id', 'person')}
          renderOption={PersonTransform.toDropdownOption.bind(this)}
          searchQuery={props.item.person && props.item.person.abbreviation}
          value={props.item.person_id}
        />
      </Form.Input>
      <Form.Checkbox
        checked={props.item.certitude}
        label={props.t('TransmissionMembershipModal.labels.certitude')}
        onChange={props.onCheckboxInputChange.bind(this, 'certitude')}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(TransmissionMembershipModal);
