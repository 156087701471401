// @flow

import { BaseService } from '@performant-software/shared-components';

/**
 * Service class for all principle_membership based API calls.
 */
class PrincipleMemberships extends BaseService {
  /**
   * Returns the principle_memberships base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/principle_memberships';
  }
}

export default new PrincipleMemberships();
