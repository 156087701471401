// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import './TextAnchorFilter.css';

import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  onClear: () => void,
  value?: string
};

const TextAnchorFilter = (props: Props) => (
  <div
    className='text-anchor-filter'
  >
    <h4>
      { props.t('TextAnchorFilter.labels.textAnchor') }
    </h4>
    <div
      className='container'
    >
      <div
        className='arabic'
        dir='rtl'
      >
        <span
          className='arabic-text'
        >
          { props.value }
        </span>
      </div>
      <Button
        basic
        content={props.t('TextAnchorFilter.buttons.clear')}
        icon='times'
        onClick={props.onClear}
      />
    </div>
  </div>
);

export default withTranslation()(TextAnchorFilter);
