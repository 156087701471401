// @flow

import React from 'react';
import {
  AssociatedDropdown,
  EmbeddedList
} from '@performant-software/semantic-components';
import {
  Form,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import AuthorshipModal from './AuthorshipModal';
import LocationModal from './LocationModal';
import TranslateField from './TranslateField';
import Locations from '../services/Locations';
import Location from '../transforms/Location';
import './ManualModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Manual } from '../types/Manual';

type Props = EditContainerProps & {
  item: Manual,
  t: (key: string) => string
};

const ManualModal = (props: Props) => (
  <Modal
    as={Form}
    className='manual-modal'
    open
    noValidate
    size='small'
  >
    <Modal.Header content={props.item.id
      ? props.t('ManualModal.title.editManual')
      : props.t('ManualModal.title.addManual')}
    />
    <Modal.Content>
      <TranslateField
        inputField={{
          autoFocus: true,
          error: props.isError('name'),
          id: 'name',
          label: props.t('ManualModal.labels.name'),
          onChange: props.onTextInputChange.bind(this, 'name'),
          required: props.isRequired('name'),
          value: props.item.name || ''
        }}
        translationField={{
          error: props.isError('arabic_name'),
          label: props.t('ManualModal.labels.arabicName'),
          layout: Arabic,
          onChange: props.onTextInputChange.bind(this, 'arabic_name'),
          required: props.isRequired('arabic_name'),
          value: props.item.arabic_name || ''
        }}
      >
        <input
          className='arabic'
          dir='rtl'
          type='text'
        />
      </TranslateField>
      <Form.Input
        error={props.isError('publication_date')}
        label={props.t('ManualModal.labels.publicationDate')}
        onChange={props.onTextInputChange.bind(this, 'publication_date')}
        required={props.required.includes('publication_date')}
        value={props.item.publication_date || ''}
      />
      <Form.Input
        error={props.isError('publication_location')}
        label={props.t('ManualModal.labels.publicationLocation')}
        required={props.required.includes('publication_location')}
      >
        <AssociatedDropdown
          collectionName='locations'
          modal={{
            component: LocationModal,
            props: {
              required: ['name']
            },
            onSave: (location) => Locations.save(location).then(({ data }) => data.location)
          }}
          onSearch={(search) => Locations.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'publication_location_id', 'publication_location')}
          renderOption={Location.toDropdownOption.bind(this)}
          searchQuery={props.item.publication_location && props.item.publication_location.name}
          value={props.item.publication_location_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('authorships')}
        label='Authors'
      >
        <EmbeddedList
          actions={[{
            name: 'edit'
          }, {
            name: 'delete'
          }]}
          collectionName='authors'
          columns={[{
            name: 'person.name',
            label: props.t('ManualModal.authorships.columns.name'),
            resolve: (authorship) => authorship.person && authorship.person.name
          }, {
            name: 'role',
            label: props.t('ManualModal.authorships.columns.role')
          }]}
          items={props.item.authorships}
          modal={{
            component: AuthorshipModal,
            props: {
              required: ['role']
            }
          }}
          onDelete={props.onDeleteChildAssociation.bind(this, 'authorships')}
          onSave={props.onSaveChildAssociation.bind(this, 'authorships')}
        />
      </Form.Input>
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(ManualModal);
