// @flow

/**
 * Returns the passed string with diacritic marks removed.
 *
 * @param str
 *
 * @returns {""|string}
 */
const stripDiacritics = (str: string) => str && str
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '');

export default {
  stripDiacritics
};
