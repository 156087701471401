// @flow

import React, { useEffect, useState } from 'react';
import { Container, Table } from 'semantic-ui-react';
import _ from 'underscore';
import StatusChip from './StatusChip';
import VariantStatuses from '../resources/VariantStatuses.json';
import Variants from '../services/Variants';
import './VersesList.css';

import type { PrincipleMembership } from '../types/PrincipleMembership';
import type { Verse } from '../types/Verse';
import type { Word } from '../types/Word';

type Props = {
  onWordSelection: (word: Word) => void,
  selectedWords: Array<Word>,
  showVariants: boolean,
  showVowels: boolean,
  verses: Array<Verse>,
  selectedStatusFilter: ?string,
  publicView: ?boolean,
  selectedVariant: ?Object,
  principleMemberships: ?Array<PrincipleMembership>,
};

const VersesList = (props: Props) => {
  const {
    onWordSelection,
    principleMemberships,
    publicView,
    selectedStatusFilter,
    selectedVariant,
    selectedWords,
    showVariants,
    showVowels,
    verses,
  } = props;

  const [variantsOfSelectedStatus, setVariantsOfSelectedStatus] = useState<Array<Object>>([]);

  const statusIsSelected = selectedStatusFilter || selectedStatusFilter === null;
  useEffect(() => {
    if (publicView && statusIsSelected) {
      Variants
        .fetchAll({
          verse_ids: verses.map((v) => v.id),
          status: selectedStatusFilter,
          empty_status: selectedStatusFilter === null,
          per_page: 0
        })
        .then(({ data }) => {
          setVariantsOfSelectedStatus(data.variants);
        });
    }
  }, [publicView, selectedStatusFilter, verses, statusIsSelected]);

  const isWordStartWordOfVariant = (wordId) => !!variantsOfSelectedStatus.find((v) => wordId === v.start_word_id);

  const doesWordHavePrinciples = (wordId) => principleMemberships && !!principleMemberships.find(
    (p) => wordId >= p.start_word_id && wordId <= p.start_word_id + p.offset
  );
  const isWordStartWordOfPrinciple = (wordId) => principleMemberships
    && !!principleMemberships.find((p) => wordId === p.start_word_id);

  /**
   * Returns the class names for the passed word.
   *
   * @param word
   *
   * @returns {string}
   */
  const getWordClass = (word) => {
    const classes = ['arabic-text'];

    if (publicView) {
      classes.push('public-arabic-text');
    }

    if (selectedWords.includes(word)) {
      classes.push(publicView ? 'public-arabic-text-selected' : 'arabic-text-selected');
    } else if (word.arabic_without_vowels) {
      classes.push('arabic-text-selectable');
    }

    if (props.showVowels) {
      classes.push('with-vowels');
    }

    return classes.join(' ');
  };

  /**
   * Renders the table row for the passed verse.
   *
   * @param verse
   *
   * @returns {*}
   */
  const renderVerse = (verse) => {
    const showPrinciples = (
      !showVariants && principleMemberships && verse.words.some((word) => doesWordHavePrinciples(word.id))
    );

    return (
      <Table.Row
        key={verse.id}
      >
        <Table.Cell
          className='verse-reference'
          verticalAlign='middle'
        >
          {`${verse.chapter.number}:${verse.number}`}
        </Table.Cell>
        <Table.Cell
          verticalAlign='middle'
          textAlign='right'
        >
          <div
            className={`${showPrinciples ? 'show-principles-arabic' : 'arabic'}`}
            dir='rtl'
          >
            { verse.words.map(renderWord.bind(this)) }
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  /**
   * Renders the passed word.
   *
   * @param word
   *
   * @returns {*}
   */
  const renderWord = (word) => {
    const showVariant = publicView && statusIsSelected && showVariants;
    const showVariantStatusChip = showVariant && isWordStartWordOfVariant(word.id);
    const showPrincipleStatusChips = !showVariants && principleMemberships && isWordStartWordOfPrinciple(word.id);
    const variantStatusObj = selectedStatusFilter ? VariantStatuses.find((v) => v.key === selectedStatusFilter) : {};
    const isSelectedWord = !!_.findWhere(selectedWords, { id: word.id });

    const principles = _.map(_.filter(principleMemberships, (pm) => pm.start_word_id === word.id), (pm) => ({
      color: pm.principle.color,
      abbreviation: pm.principle.name,
      text: pm.principle.rule
    }));

    const isRootWordOfSelectedVariant = selectedVariant
      ? word.id >= selectedVariant.start_word_id
        && word.id
          <= selectedVariant.start_word_id + selectedVariant.offset
      : false;

    return (
      <button
        key={word.id}
        className={
          `arabic-button \
          ${showVariantStatusChip ? 'padded-arabic-button' : ''}\
          ${showPrincipleStatusChips ? 'show-principles-arabic-button' : ''}`
        }
        onClick={word.arabic_without_vowels ? onWordSelection.bind(this, word) : undefined}
        type='button'
      >
        <span
          className={getWordClass(word)}
        >
          <span
            className={(showVariants && word.variants_count) || (!showVariants && doesWordHavePrinciples(word.id))
              ? 'with-variant-or-principle' : ''}
          >
            { showVowels ? word.arabic_with_vowels : word.arabic_without_vowels }
          </span>
          { publicView && (isSelectedWord || isRootWordOfSelectedVariant) && (
            <div
              className='selected-text-underlay'
              style={isRootWordOfSelectedVariant ? { outline: '2px solid red' } : {}}
            />
          )}
        </span>
        { showVariantStatusChip
          && (
            <div className='status-chip-container'>
              <StatusChip
                disablePopup={false}
                isPrinciple={false}
                status={variantStatusObj}
              />
            </div>
          )}
        { showPrincipleStatusChips
          && (
            <div className='principle-status-chip-container'>
              { _.map(principles, (principle) => (
                <StatusChip
                  disablePopup={false}
                  isPrinciple
                  status={{
                    ...principle,
                    abbreviation: principle.abbreviation,
                  }}
                  key={principle.abbreviation}
                />
              ))}
            </div>
          )}
      </button>
    );
  };

  /**
   * Renders the VersesList view.
   *
   * @returns {*}
   */
  return (
    <Container
      className='verses-list'
    >
      <Table
        basic
        padded='very'
        renderBodyRow={renderVerse.bind(this)}
        striped
        tableData={verses}
      />
    </Container>
  );
};

export default VersesList;
