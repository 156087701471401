// @flow

import React, { Component } from 'react';
import { Toaster } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Icon,
  Message,
  Portal,
  Segment
} from 'semantic-ui-react';
import _, { isEmpty } from 'underscore';
import PrincipleMembershipPortal from '../components/PrincipleMembershipPortal';
import TextSidebar, { Modes } from '../components/TextSidebar';
import VersesList from '../components/VersesList';
import Annotations from '../services/Annotations';
import Chapters from '../services/Chapters';
import PrincipleMemberships from '../services/PrincipleMemberships';
import Variants from '../services/Variants';
import Verses from '../services/Verses';
import Words from '../services/Words';
import type { Chapter } from '../types/Chapter';
import type { PrincipleMembership } from '../types/PrincipleMembership';
import type { Variant } from '../types/Variant';
import type { Verse } from '../types/Verse';
import type { Word } from '../types/Word';

import './Text.css';

type Props = {
  t: (key: string, params?: any) => string,
  publicView: ?boolean
};

type State = {
  chapter: ?number,
  chapters: Array<Chapter>,
  page: number,
  pages: number,
  selectedWords: Array<Word>,
  showVariants: boolean,
  showVowels: boolean,
  verse: ?number,
  verses: Array<Verse>,
  verseDisabled: boolean,
  selectedStatusFilter: ?string,
  variantsList: ?Array<Variant>,
  rootWordIdOfClickedVariant: ?number,
  rootWordOfClickedVariant: ?Word,
  selectedVariant: ?Variant,
  filterParams: Object,
  principleMemberships: ?Array<PrincipleMembership>,
  savingAnnotation: boolean,
  showPrincipleMembershipsToaster: boolean,
  principleMembershipsErrors: ?Array<string>,
  mobileOpenSidebar: boolean,
  isMobile: boolean
};

const SESSION_KEY = 'Text';
const SPACE = ' ';
const ERROR_UNIQUE_ANNOTATION_PRINCIPLE = 'Principle, transmission, manual, start word, and offset must be unique';

class Text extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      chapter: null,
      chapters: [],
      page: 1,
      pages: 1,
      selectedWords: [],
      showVariants: true,
      showVowels: false,
      verse: null,
      verses: [],
      verseDisabled: false,
      selectedStatusFilter: '',
      variantsList: null,
      rootWordIdOfClickedVariant: null,
      rootWordOfClickedVariant: {},
      selectedVariant: null,
      filterParams: {},
      principleMemberships: [],
      savingAnnotation: false,
      showPrincipleMembershipsToaster: false,
      principleMembershipsErrors: [],
      mobileOpenSidebar: false,
      isMobile: window.matchMedia('(max-width: 991px)').matches
    };
  }

  /**
   * Restores the stored session information and loads the list of chapters.
   */
  componentDidMount() {
    this.restoreSession();

    const mediaQuery = window.matchMedia('(max-width: 991px)');
    const mediaListener = () => this.setState({ isMobile: mediaQuery.matches });
    window.addEventListener('resize', mediaListener);

    Chapters
      .fetchAll()
      .then(({ data: { chapters } }) => {
        this.setState({ chapters });
        this.fetchData();
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rootWordIdOfClickedVariant !== prevState.rootWordIdOfClickedVariant && this.props.publicView) {
      if (this.state.rootWordIdOfClickedVariant === null) {
        this.setState({ rootWordOfClickedVariant: {} });
      } else {
        Words
          .fetchAll({ ids: [this.state.rootWordIdOfClickedVariant] })
          .then(({ data: { words } }) => {
          // changes chapter and verse when sidebarVariant is selected
            const word = _.first(words);
            this.goToChapterAndVerse(word.verse.chapter.number, word.verse.number);
            this.setState({ rootWordOfClickedVariant: word });
          });
      }
    }
    if (
      !this.state.showVariants
      && this.state.verses
      && this.state.verses.length
      && (this.state.showVariants !== prevState.showVariants
        || this.state.verses !== prevState.verses)
    ) {
      this.fetchPrincipleMemberships();
    }
  }

  /**
   * Fetches principle memberships and sets them on the state.
   */
  fetchPrincipleMemberships() {
    return PrincipleMemberships
      .fetchAll({
        verse_ids: this.state.verses.map((v) => v.id),
        per_page: 0,
      })
      // eslint-disable-next-line camelcase
      .then(({ data: { principle_memberships } }) => {
        this.setState({ principleMemberships: principle_memberships });
        return Promise.resolve(principle_memberships);
      });
  }

  /**
   * Removes principle membership from the state.
   */
  removePrincipleMembership(id) {
    if (
      this.state.principleMemberships
      && this.state.principleMemberships.some((pm) => pm.id === id)
    ) {
      this.setState((prevState) => {
        const newPrincipleMemberships = prevState.principleMemberships
          ? prevState.principleMemberships.filter((pm) => pm.id !== id)
          : [];
        return {
          ...prevState,
          principleMemberships: newPrincipleMemberships,
        };
      });
    }
  }

  /**
   * Sets the chapter and verse on the state. Stores the session information.
   *
   * @param chapter
   * @param verse
   */
  goToChapterAndVerse(chapter, verse) {
    if (
      chapter !== this.state.chapter
      || (verse !== this.state.verse && !this.state.verseDisabled)
    ) {
      this.setState({
        chapter,
        verse,
        verses: [],
      }, () => {
        this.setSession();
        this.fetchData();
      });
    }
  }

  /**
   * Loads the data and sets the results on the state.
   */
  fetchData(more = false) {
    const { chapter, verse, page } = this.state;

    Verses
      .fetchAll({
        chapter,
        verse,
        page,
        more
      })
      .then(({ data }) => {
        let index = this.getMaxWordIndex();

        // Assign an index to each word of the verses
        const verses = _.map(data.verses, (v) => ({
          ...v,
          words: _.map(v.words, (w) => {
            if (w.arabic_without_vowels) {
              index += 1;
            }
            return { ...w, index };
          })
        }));

        this.setState((state) => ({
          verses: [
            ...state.verses,
            ...verses
          ],
          page: data.list.page,
          pages: data.list.pages,
          verseDisabled: data.list.pages <= 1
        }));
      });
  }

  /**
   * Returns the formatted chapter options.
   *
   * @returns {Array<{text: string, value: *, key: *}>}
   */
  getChapterOptions() {
    return this.state.chapters.map((chapter) => ({
      key: chapter.number,
      value: chapter.number,
      text: this.props.t('Text.chapter', {
        number: chapter.number,
        title: chapter.title,
        subtitle: chapter.arabic_title
      })
    }));
  }

  /**
   * Returns the index of the last word in the verses list.
   *
   * @returns {number}
   */
  getMaxWordIndex() {
    if (!this.state.verses.length) {
      return 0;
    }

    const verse = _.last(this.state.verses);
    const lastWord = _.max(verse.words, (w) => w.index);

    return (lastWord && lastWord.index) || 0;
  }

  /**
   * Returns the formatted verse options.
   *
   * @returns {[]}
   */
  getVerseOptions() {
    const verseOptions = [];

    const chapter = _.findWhere(this.state.chapters, { number: this.state.chapter });

    if (chapter) {
      for (let i = 1; i <= chapter.verse_count; i += 1) {
        verseOptions.push({
          key: i,
          value: i,
          text: this.props.t('Text.verse', { number: i })
        });
      }
    }

    return verseOptions;
  }

  /**
   * Returns true if the passed collection of words are sequential.
   *
   * @param words
   *
   * @returns {boolean}
   */
  isSequential(words) {
    let isSequential = true;

    const indexes = _.pluck(words, 'index');
    let lastIndex;

    // Iterates of the list of indexes. The loop will terminate on the first word that is not sequential.
    _.every(indexes, (index) => {
      if (lastIndex) {
        isSequential = Math.abs(index - lastIndex) === 1;
      }

      lastIndex = index;
      return isSequential;
    });

    return isSequential;
  }

  /**
   * Sets the chapter and verse on the state. Stores the session information.
   *
   * @param e
   * @param value
   */
  onChapterSelection(e, { value }, page) {
    this.setState({
      chapter: value,
      verse: null,
      verses: [],
      page: page || 1,
    }, () => {
      this.setSession();
      this.fetchData();
    });
  }

  /**
   * Clears the currently selected words.
   */
  onClearButton() {
    this.setState({ selectedWords: [] });
  }

  /**
   * Requests annotation deletion from the APi
   *
   * @param principleMembership
   */
  onDeleteAnnotation(principleMembership) {
    this.setState({ savingAnnotation: true }, () => {
      Annotations
        .delete({ id: principleMembership.annotation_id })
        .finally(() => {
          this.setState({ savingAnnotation: false });
          this.fetchPrincipleMemberships();
        });
    });
  }

  /**
   * Increments the page number and loads the verses.
   */
  onLoadMore() {
    this.setState((state) => ({ page: state.page + 1 }), () => {
      this.setSession();
      this.fetchData(true);
    });
  }

  /**
   * Saves the annotation to the API and catches validation errors
   *
   * @param annotation
   */
  onSaveAnnotation(annotation) {
    const validationErrors = [];
    this.setState((state) => ({
      ...state,
      showPrincipleMembershipsToaster: true,
      savingAnnotation: true
    }), () => {
      Annotations.save(annotation)
        .catch(({ response: { data: { errors } } }) => {
          if (errors) {
            _.each(Object.keys(errors), (key) => {
              const fieldErrors = errors[key];
              _.each(fieldErrors, (error) => {
                _.extend(validationErrors, this.resolveValidationError(error, annotation));
              });
            });
          }
          this.setState({ principleMembershipsErrors: _.values(validationErrors) });
        })
        .finally(() => {
          this.setState((state) => ({ ...state, savingAnnotation: false }));
          this.fetchPrincipleMemberships();
        });
    });
  }

  /**
   * Sets the showVariants property on the state. Store the session information.
   *
   * @param show
   */
  onShowHideVariantsSelection(show) {
    this.setState(() => ({ showVariants: show }), this.setSession.bind(this));
  }

  /**
   * Sets the showVowels property on the state. Stores the session information.
   */
  onShowVowelsSelection() {
    this.setState((state) => ({ showVowels: !state.showVowels }), this.setSession.bind(this));
  }

  /**
   * Filters variantList based
   *
   */
  handleUpdateSidebar = (filterParams, words) => {
    if (isEmpty(words)) {
      this.setState({ selectedWords: [], variantsList: [] });
    } else {
      const word = _.first(words);

      Variants
        .fetchAll({
          word_id: word ? word.id : null,
          per_page: 0,
          ...filterParams
        })
        .then(({ data }) => {
          this.setState({ variantsList: data.variants, });
        });
    }

    this.setState({ filterParams });
  };

  /**
   * Toggles selection of the passed word.
   *
   * @param word
   */
  onWordSelection(word) {
    const isSelected = !!_.findWhere(this.state.selectedWords, { id: word.id });

    let selectedWords;

    // Only one word can be selected at a time in unauthenticated view
    if (this.props.publicView) {
      if (isSelected) {
        selectedWords = [];
        this.setState({ variantsList: [] });
      } else {
        selectedWords = [word];
        this.setState({ selectedVariant: null });
        Variants
          .fetchAll({
            ...this.state.filterParams || {},
            word_id: word.id,
            per_page: 0
          })
          .then(({ data }) => {
            this.setState({ variantsList: data.variants });
          });
      }
    } else if (isSelected) {
      selectedWords = _.sortBy(_.filter(this.state.selectedWords, (w) => w.id !== word.id), 'id');

      // If un-selecting the passed word makes the list non-sequential, set the list to be empty
      if (!this.isSequential(selectedWords)) {
        selectedWords = [];
      }
    } else {
      selectedWords = _.sortBy([...this.state.selectedWords, word], 'id');

      // If the selected words makes the list non-sequential, set the list to be the selected word
      if (!this.isSequential(selectedWords)) {
        selectedWords = [word];
      }
    }
    this.setState({ selectedWords });
  }

  /**
   * Sets the verse selection on the state. Stores the session information.
   *
   * @param e
   * @param value
   */
  onVerseSelection(e, { value }) {
    this.setState({ verse: value, verses: [], page: 1 }, () => {
      this.setSession();
      this.fetchData();
    });
  }

  onStatusFilterSelection = (e, data) => {
    const newValue = data.value.length ? data.value[data.value.length - 1] : '';
    this.setState({ selectedStatusFilter: newValue });
  }

  onSelectSidebarVariant = (startWordId) => {
    if (this.state.rootWordIdOfClickedVariant === startWordId) {
      const chapter = this.state.rootWordOfClickedVariant
        ? this.state.rootWordOfClickedVariant.verse.chapter.number
        : null;
      const verse = this.state.rootWordOfClickedVariant
        ? this.state.rootWordOfClickedVariant.verse.number
        : null;
      if (chapter !== this.state.chapter || verse !== this.state.verse) {
        this.goToChapterAndVerse(chapter, verse);
      }
    } else {
      this.setState({ rootWordIdOfClickedVariant: startWordId });
    }
  }

  onVariantWordClick = (variantWord) => {
    const selectedVariantId = this.state.selectedVariant ? this.state.selectedVariant.id : null;
    const isSelected = variantWord.id === selectedVariantId;
    if (isSelected) {
      this.setState({ selectedVariant: null });
      this.onSelectSidebarVariant(null);
    } else {
      this.setState({ selectedVariant: variantWord });
      this.onSelectSidebarVariant(variantWord.start_word_id);
    }
  };

  /**
   * Renders the Text view.
   *
   * @returns {*}
   */
  render() {
    return (
      <>
        <Button
          attached='left'
          className='mobile-sidebar-toggle'
          onClick={() => this.setState((prev) => ({ mobileOpenSidebar: !prev.mobileOpenSidebar }))}
        >
          <Icon name='filter' />
        </Button>
        <Grid className='text-sidebar' divided>
          <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column
              width={this.props.publicView && !this.state.isMobile ? 11 : 16}
              className='text-column'
              style={{ paddingBottom: 14 }}
            >
              { this.renderFilters() }
              { this.renderContent() }
              { this.renderLoadButton() }
              { this.renderSelectionButtons() }
              {!this.props.publicView && !this.state.showVariants
                && (
                  <>
                    <PrincipleMembershipPortal
                      fetchPrincipleMemberships={this.fetchPrincipleMemberships.bind(this)}
                      onClearButton={this.onClearButton.bind(this)}
                      onDeleteAnnotation={this.onDeleteAnnotation.bind(this)}
                      onSaveAnnotation={this.onSaveAnnotation.bind(this)}
                      principleMemberships={this.state.principleMemberships}
                      renderSelectedWord={this.renderSelectedWord}
                      savingAnnotation={this.state.savingAnnotation}
                      words={this.state.selectedWords}
                    />
                    { this.renderToaster() }
                  </>
                )}
            </Grid.Column>
            { this.props.publicView && (
              <TextSidebar
                filterParams={this.state.filterParams}
                isMobile={this.state.isMobile}
                mobileOpen={this.state.mobileOpenSidebar}
                mode={this.state.showVariants ? Modes.variants : Modes.principles}
                onStatusFilterSelection={this.onStatusFilterSelection}
                onVariantWordClick={this.onVariantWordClick}
                onUpdateSidebar={this.handleUpdateSidebar}
                selectedStatusFilter={this.state.selectedStatusFilter}
                selectedVariant={this.state.selectedVariant}
                selectedWord={this.state.selectedWords[0]}
                variantsList={this.state.variantsList}
                width={5}
              />
            )}
          </Grid.Row>
        </Grid>
      </>
    );
  }

  /**
   * Renders the page content.
   *
   * @returns {*}
   */
  renderContent() {
    return (
      <div
        className='verses-list-container'
      >
        <VersesList
          selectedVariant={this.state.selectedVariant}
          onWordSelection={this.onWordSelection.bind(this)}
          selectedWords={this.state.selectedWords}
          showVariants={this.state.showVariants}
          showVowels={this.state.showVowels}
          verses={this.state.verses}
          selectedStatusFilter={this.state.selectedStatusFilter}
          publicView={this.props.publicView}
          principleMemberships={this.state.principleMemberships}
        />
      </div>
    );
  }

  /**
   * Renders the page filters.
   *
   * @returns {*}
   */
  renderFilters() {
    return (
      <Segment
        as={Container}
        className='filters'
      >
        <Grid
          columns={2}
          doubling
          padded
          verticalAlign='middle'
        >
          <Grid.Column>
            <Dropdown
              fluid
              onChange={this.onChapterSelection.bind(this)}
              options={this.getChapterOptions()}
              placeholder={this.props.t('Text.selectChapter')}
              search
              selection
              value={this.state.chapter}
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              clearable
              disabled={this.state.verseDisabled}
              fluid
              onChange={this.onVerseSelection.bind(this)}
              options={this.getVerseOptions()}
              placeholder={this.props.t('Text.selectVerse')}
              search
              selection
              value={this.state.verse}
            />
          </Grid.Column>
          <Grid.Column>
            <Button.Group vertical={this.state.isMobile} className='text-view-buttons'>
              <Button
                active={this.state.showVariants}
                onClick={() => this.onShowHideVariantsSelection(true)}
                primary={this.state.showVariants}
              >
                { this.props.t('Text.showVariants') }
              </Button>
              <Button
                active={!this.state.showVariants}
                onClick={() => this.onShowHideVariantsSelection(false)}
                primary={!this.state.showVariants}
              >
                { this.props.t('Text.showPrinciples') }
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              checked={this.state.showVowels}
              className='toggle-vowels-checkbox'
              label={this.state.showVowels
                ? this.props.t('Text.hideVowels')
                : this.props.t('Text.showVowels')}
              onChange={this.onShowVowelsSelection.bind(this)}
              toggle
            />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  /**
   * Renders the load more button component.
   *
   * @returns {null|*}
   */
  renderLoadButton() {
    if (this.state.page >= this.state.pages) {
      return null;
    }

    return (
      <div
        className='load-more-button'
      >
        <Button
          content={this.props.t('Text.buttons.loadMore')}
          icon='arrow alternate circle down outline'
          onClick={this.onLoadMore.bind(this)}
          primary
        />
      </div>
    );
  }

  /**
   * Renders the passed word.
   *
   * @param word
   *
   * @returns {*}
   */
  renderSelectedWord(word) {
    return this.state.showVowels ? word.arabic_with_vowels : word.arabic_without_vowels;
  }

  /**
   * Renders the edit/clear buttons (if not public view, and 'show variants' selected).
   *
   * @returns {null|*}
   */
  renderSelectionButtons() {
    if (!this.state.selectedWords.length || this.props.publicView || !this.state.showVariants) {
      return null;
    }

    return (
      <Portal
        open={!!this.state.selectedWords.length}
      >
        <Segment
          className='selection-button-container'
          textAlign='center'
        >
          <div
            className='arabic-selection'
          >
            { this.state.selectedWords.map(this.renderSelectedWord.bind(this)).join(SPACE) }
          </div>
          <Button
            as={Link}
            className='selection-button'
            color='green'
            content={this.props.t('Text.buttons.edit')}
            disabled={!this.state.selectedWords.length}
            to={{
              pathname: '/entry/edit/',
              state: { wordIds: this.state.selectedWords.map((word) => word.id) }
            }}
          />
          <Button
            className='selection-button'
            color='red'
            content={this.props.t('Text.buttons.clear')}
            disabled={!this.state.selectedWords.length}
            onClick={this.onClearButton.bind(this)}
          />
        </Segment>
      </Portal>
    );
  }

  /**
   * Renders the toaster component for principle membership errors.
   *
   * @returns {null|*}
   */
  renderToaster() {
    if (!this.state.showPrincipleMembershipsToaster) {
      return null;
    }

    if (!(this.state.principleMembershipsErrors
      && this.state.principleMembershipsErrors.length)) {
      return null;
    }

    return (
      <Toaster
        onDismiss={() => this.setState({ showPrincipleMembershipsToaster: false })}
        timeout={0}
        type={Toaster.MessageTypes.negative}
      >
        <Message.Header
          content={this.props.t('Common.errors.title')}
        />
        <Message.List
          items={this.state.principleMembershipsErrors}
        />
      </Toaster>
    );
  }

  /**
   * Returns the formatted error message for the passed error and annotation.
   *
   * @param error
   * @param item
   *
   * @returns {null|*}
   */
  resolveValidationError(error, item) {
    if (error === ERROR_UNIQUE_ANNOTATION_PRINCIPLE) {
      // eslint-disable-next-line camelcase
      const { principle_membership } = item;
      return {
        principle_membership_id: this.props.t('AnnotationsList.errors.unique.principleOnly', {
          principle: principle_membership.principle && principle_membership.principle.name,
        })
      };
    }
    return null;
  }

  /**
   * Restores the chapter, verse, and showVowels values from the session.
   */
  restoreSession() {
    const session = sessionStorage.getItem(SESSION_KEY) || '{}';
    const {
      chapter = 1,
      verse,
      showVariants = true,
      showVowels = false,
    } = JSON.parse(session);

    this.setState({
      chapter,
      verse,
      showVariants,
      showVowels,
    });
  }

  /**
   * Stores the chapter, verse, and showVowels values in the session.
   */
  setSession() {
    const {
      chapter,
      page,
      verse,
      showVariants,
      showVowels,
    } = this.state;

    sessionStorage.setItem(SESSION_KEY, JSON.stringify({
      chapter,
      page,
      verse,
      showVariants,
      showVowels,
    }));
  }
}

export default withTranslation()(Text);
