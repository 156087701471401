// @flow

import React from 'react';
import {
  type EditContainerProps
} from '@performant-software/shared-components/types';
import {
  Form,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import './VariantTypeModal.css';

import type { VariantType } from '../types/VariantType';

type Props = EditContainerProps & { item: VariantType };

const VariantTypeModal = (props: Props) => (
  <Modal
    as={Form}
    open
    noValidate
    size='small'
  >
    <Modal.Header content={props.item.id
      ? props.t('VariantTypeModal.title.editVariantType')
      : props.t('VariantTypeModal.title.addVariantType')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('abbreviation')}
        label={props.t('VariantTypeModal.labels.abbreviation')}
        onChange={props.onTextInputChange.bind(this, 'abbreviation')}
        required={props.isRequired('abbreviation')}
        value={props.item.abbreviation || ''}
      />
      <Form.Input
        error={props.isError('name')}
        label={props.t('VariantTypeModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('description')}
        label={props.t('VariantTypeModal.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        required={props.isRequired('description')}
        value={props.item.description || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(VariantTypeModal);