// @flow

import React from 'react';
import { KeyboardField } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import {
  Dropdown,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import Languages from '../config/Languages';
import './FootnoteModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Footnote } from '../types/Footnote';

type Props = EditContainerProps & {
  item: Footnote,
  t: (key: string) => string
};

/**
 * Renders the ArabicBody text input component. This component is only used by the FootnoteModal if the language
 * is set the "Arabic".
 *
 * @param props
 *
 * @returns {*}
 *
 * @constructor
 */
const ArabicBody = (props: Props) => (
  <KeyboardField
    error={props.isError('body')}
    label={props.t('FootnoteModal.labels.body')}
    layout={Arabic}
    onChange={props.onTextInputChange.bind(this, 'body')}
    required={props.isRequired('label')}
    value={props.item.body || ''}
  >
    <Form.TextArea
      className={props.item.language === Languages.arabic ? 'arabic' : ''}
      onChange={props.onTextInputChange.bind(this, 'body')}
      value={props.item.body || ''}
    />
  </KeyboardField>
);

/**
 * Renders the EnglishBody text input component. This component is only used by the FootnoteModal if the language
 * is set the "English".
 *
 * @param props
 *
 * @returns {*}
 *
 * @constructor
 */
const EnglishBody = (props: Props) => (
  <Form.TextArea
    className={props.item.language === Languages.arabic ? 'arabic-text-area' : ''}
    error={props.isError('label')}
    label={props.t('FootnoteModal.labels.body')}
    onChange={props.onTextInputChange.bind(this, 'body')}
    required={props.isRequired('body')}
    value={props.item.body || ''}
  />
);

/**
 * Renders the FootnoteModal component.
 *
 * @param props
 *
 * @returns {*}
 *
 * @constructor
 */
const FootnoteModal = (props: Props) => (
  <Modal
    as={Form}
    className='footnote-modal'
    open
    noValidate
    size='small'
  >
    <Modal.Header>
      <Grid
        columns={2}
      >
        <Grid.Column
          textAlign='left'
        >
          <Header content={props.item.id
            ? props.t('FootnoteModal.title.editFootnote')
            : props.t('FootnoteModal.title.addFootnote')}
          />
        </Grid.Column>
        <Grid.Column
          textAlign='right'
        >
          <Dropdown
            onChange={props.onTextInputChange.bind(this, 'language')}
            options={[{
              key: Languages.english,
              text: props.t('Common.languages.english'),
              value: Languages.english
            }, {
              key: Languages.arabic,
              text: props.t('Common.languages.arabic'),
              value: Languages.arabic
            }]}
            value={props.item.language}
          />
        </Grid.Column>
      </Grid>
    </Modal.Header>
    <Modal.Content>
      <Form.Input
        autoFocus
        error={props.isError('label')}
        label={props.t('FootnoteModal.labels.label')}
        onChange={props.onTextInputChange.bind(this, 'label')}
        required={props.isRequired('label')}
        value={props.item.label || ''}
      />
      { props.item.language === Languages.english ? <EnglishBody {...props} /> : <ArabicBody {...props} /> }
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(FootnoteModal);
