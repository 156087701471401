// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import './MultiAddButton.css';

type Props = {
  index: number,
  onClick: () => void,
  t: (key: string) => string,
};

const MultiAddButton = (props: Props) => {
  return (
    <Button
      basic
      content={props.t('Common.buttons.multiAdd')}
      icon='plus'
      key={`multiadd-${props.index}`}
      onClick={props.onClick.bind(this)}
    />
  );
};

export default withTranslation()(MultiAddButton);
