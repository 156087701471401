// @flow

import React, { Component, createRef } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  withRouter,
  type ElementRef,
  type RouterHistory
} from 'react-router-dom';
import {
  Container,
  Icon,
  Menu, Ref,
  Sidebar
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Authentication from '../services/Authentication';
import Session from '../services/Session';
import Edit from './Edit';
import Manuals from './Manuals';
import Locations from './Locations';
import Pages from './Pages';
import People from './People';
import Principles from './Principles';
import ScrollViewContext from '../contexts/ScrollViewContext';
import Text from './Text';
import Transmissions from './Transmissions';
import VariantTypes from './VariantTypes';
import './Entry.css';
import '../styles/Arabic.css';

type Props = {
  history: RouterHistory,
  t: (key: string) => string
}

type State = {
  sidebarVisible: boolean
}

class Entry extends Component<Props, State> {
  containerRef: {
    current: null | ElementRef<Sidebar.Pushable>
  };

  /**
   * Constructs a new admin page.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      sidebarVisible: false
    };

    this.containerRef = createRef();
  }

  /**
   * Sets the sidebar to be non-visible.
   */
  onCloseSidebar() {
    this.setState({ sidebarVisible: false });
  }

  /**
   * Logs the user out and removes the current session.
   */
  onLogoutButton() {
    Authentication
      .logout()
      .then(() => {
        Session.destroy();
        this.props.history.push('/');
      });
  }

  /**
   * Scrolls the container ref to the top.
   */
  scrollToTop() {
    if (this.containerRef && this.containerRef.current) {
      this.containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  /**
   * Renders the Admin page.
   *
   * @returns {*}
   */
  render() {
    return (
      <Ref
        innerRef={this.containerRef}
      >
        <ScrollViewContext.Provider
          value={{
            scrollToTop: this.scrollToTop.bind(this)
          }}
        >
          <Sidebar.Pushable
            className='root-container'
          >
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              onHide={this.onCloseSidebar.bind(this)}
              vertical
              visible={this.state.sidebarVisible}
              width='thin'
            >
              <Menu.Item
                as={Link}
                to='/'
              >
                <Icon name='home' />
                { this.props.t('Entry.menu.home') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/text/'
              >
                <Icon name='file alternate outline' />
                { this.props.t('Entry.menu.text') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/people/'
              >
                <Icon name='users' />
                { this.props.t('Entry.menu.people') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/locations/'
              >
                <Icon name='map marker alternate' />
                { this.props.t('Entry.menu.locations') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/manuals/'
              >
                <Icon name='book' />
                { this.props.t('Entry.menu.manuals') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/transmissions/'
              >
                <Icon name='chain' />
                { this.props.t('Entry.menu.transmissions') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/principles/'
              >
                <Icon name='balance' />
                { this.props.t('Entry.menu.principles') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/variant_types/'
              >
                <Icon name='list alternate outline' />
                { this.props.t('Entry.menu.variantTypes') }
              </Menu.Item>
              <Menu.Item
                as={Link}
                onClick={this.onCloseSidebar.bind(this)}
                to='/entry/pages/'
              >
                <Icon name='copy outline' />
                { this.props.t('Entry.menu.pages') }
              </Menu.Item>
              <Menu.Item
                as='a'
                onClick={this.onLogoutButton.bind(this)}
              >
                <Icon name='log out' />
                { this.props.t('Entry.menu.logout') }
              </Menu.Item>
            </Sidebar>
            <Menu
              className='main-menu'
              inverted
              size='huge'
            >
              <Container>
                <Menu.Item
                  icon='bars'
                  onClick={() => this.setState({ sidebarVisible: true })}
                />
                <Menu.Item
                  as='a'
                  header
                >
                  <Icon name='book' />
                  { this.props.t('Entry.title') }
                </Menu.Item>
              </Container>
            </Menu>
            <Container
              className='main-container'
            >
              <Switch>
                <Route path='/entry/edit/' component={Edit} />
                <Route path='/entry/manuals/' component={Manuals} />
                <Route path='/entry/locations/' component={Locations} />
                <Route path='/entry/pages/' component={Pages} />
                <Route path='/entry/people/' component={People} />
                <Route path='/entry/principles/' component={Principles} />
                <Route path='/entry/text/' component={Text} />
                <Route path='/entry/transmissions' component={Transmissions} />
                <Route path='/entry/variant_types' component={VariantTypes} />
                <Redirect to='/entry/text/' />
              </Switch>
            </Container>
          </Sidebar.Pushable>
        </ScrollViewContext.Provider>
      </Ref>
    );
  }
}

export default withTranslation()(withRouter(Entry));
