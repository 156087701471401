// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Quill } from 'react-quill';
import { Tweet } from 'react-twitter-widgets';

const BlockEmbed = Quill.import('blots/block/embed');

class TweetBlot extends BlockEmbed {
  static create(link) {
    let node = super.create();
    // Trim the link to just the tweet ID
    const id = link.match(/\d+$/)[0];
    node.dataset.id = id;

    ReactDOM.render(<Tweet tweetId={id} />, node);

    return node;
  }

  static value(domNode) {
    return domNode.dataset.id;
  }
}
TweetBlot.blotName = 'tweet';
TweetBlot.tagName = 'div';
TweetBlot.className = 'tweet';

export default TweetBlot;
