// @flow

import React, { Component } from 'react';
import {
  BooleanIcon,
  EmbeddedList,
  Selectize
} from '@performant-software/semantic-components';
import {
  Form,
  Icon,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import MultiAddButton from './MultiAddButton';
import People from '../services/People';
import PersonModal from './PersonModal';
import TransmissionMembership from '../transforms/TransmissionMembership';
import TransmissionMembershipModal from './TransmissionMembershipModal';
import './TransmissionModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Transmission } from '../types/Transmission';

type Props = EditContainerProps & { item: Transmission };

type State = {
  modalSelectize: boolean
};

class TransmissionModal extends Component<Props, State> {
  /**
   * Constructs a new TransmisionModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalSelectize: false
    };
  }

  /**
   * Adds the passed collection of people as transmission_memberships for the current transmission.
   *
   * @param people
   */
  onAddPeople(people) {
    // Hide the people selectize modal
    this.setState({ modalSelectize: false });

    // Find an existing non-deleted membership
    const findMembership = (person) => _.find(
      this.props.item.transmission_memberships,
      (tm) => !tm._destroy && tm.person_id === person.id
    );

    const memberships = _.map(people, (person) => findMembership(person) || TransmissionMembership.fromPerson(person));
    this.props.onMultiAddChildAssociations('transmission_memberships', memberships);
  }

  /**
   * Moves the item to the specified drag index.
   *
   * @param dragIndex
   * @param hoverIndex
   */
  onDrag(dragIndex, hoverIndex) {
    const items = [...this.props.item.transmission_memberships];
    const item = items[dragIndex];

    items.splice(dragIndex, 1);
    items.splice(hoverIndex, 0, item);

    // Set the sequence property according to the index
    this.props.onSetState({
      transmission_memberships: items
    });
  }

  /**
   * Validates the passed transmission membership can be added to the current chain of transmission.
   *
   * @param tm
   *
   * @returns {[]}
   */
  validate(tm) {
    const validationErrors = {};

    // Add a validation error if a membership with the same user already exists
    const findMembership = (t) => tm.id !== t.id && t.person_id === tm.person_id && !t._destroy;

    if (_.find(this.props.item.transmission_memberships, findMembership)) {
      _.extend(validationErrors, {
        person_id: this.props.t('TransmissionModal.errors.duplicateMembership', {
          name: tm.person.name
        })
      });
    }

    return validationErrors;
  }

  /**
   * Renders the TransmissionModal component.
   */
  render() {
    return (
      <Modal
        as={Form}
        className='transmission-modal'
        open
        noValidate
        size='small'
      >
        <Modal.Header
          content={this.props.item.id
            ? this.props.t('TransmissionModal.title.editTransmission')
            : this.props.t('TransmissionModal.title.addTransmission')}
        />
        <Modal.Content>
          <EmbeddedList
            actions={[{
              name: 'edit'
            }, {
              name: 'copy'
            }, {
              name: 'delete'
            }]}
            addButton={{
              location: 'top'
            }}
            buttons={[{
              render: (index) => (
                <MultiAddButton
                  index={index}
                  onClick={() => this.setState({ modalSelectize: true })}
                />
              )
            }]}
            collectionName='transmission_memberships'
            columns={[{
              className: 'drag-drop-icon',
              name: 'move',
              label: '',
              render: () => <Icon color='grey' name='bars' />
            }, {
              className: 'arrow-icon',
              name: 'arrow',
              label: '',
              render: () => <Icon color='grey' name='arrow down' />
            }, {
              name: 'person.name',
              label: this.props.t('TransmissionModal.memberships.columns.person'),
              resolve: (membership) => membership.person && membership.person.name
            }, {
              name: 'certitude',
              label: this.props.t('TransmissionModal.memberships.columns.certitude'),
              render: (membership) => <BooleanIcon value={membership.certitude} />
            }]}
            items={this.props.item.transmission_memberships}
            modal={{
              component: TransmissionMembershipModal,
              props: {
                defaults: {
                  certitude: true
                },
                validate: this.validate.bind(this)
              }
            }}
            onDrag={this.onDrag.bind(this)}
            onDelete={this.props.onDeleteChildAssociation.bind(this, 'transmission_memberships')}
            onSave={this.props.onSaveChildAssociation.bind(this, 'transmission_memberships')}
          />
        </Modal.Content>
        { this.renderPeopleSelectize() }
        { this.props.children }
      </Modal>
    );
  }

  /**
   * Renders the people Selectize component.
   *
   * @returns {null|*}
   */
  renderPeopleSelectize() {
    if (!this.state.modalSelectize) {
      return null;
    }

    return (
      <Selectize
        collectionName='people'
        onClose={() => this.setState({ modalSelectize: false })}
        onLoad={(params) => People.fetchAll(_.extend(params, { sort_by: 'name' }))}
        onSave={this.onAddPeople.bind(this)}
        modal={{
          component: PersonModal,
          onSave: (person) => (
            People
              .save(person)
              .then(({ data }) => data.person)
          ),
          props: {
            required: ['name', 'abbreviation']
          }
        }}
        renderItem={(person) => person.name}
        selectedItems={_.map(_.filter(this.props.item.transmission_memberships,
          (tm) => !tm._destroy), (tm) => tm.person)}
        title={this.props.t('TransmissionModal.title.selectPeople')}
      />
    );
  }
}

export default withTranslation()(TransmissionModal);
