// @flow

import React, { useCallback, useMemo } from 'react';
import { BooleanIcon, DescriptorField } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import { Button, Popup, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import AttachmentsList from './AttachmentsList';
import ChapterVerse from './ChapterVerse';
import { getAnchorProps } from '../utils/Words';
import { renderDeleteModal } from '../utils/Annotations';
import ScrollableListTable from './ScrollableListTable';
import Variant from '../transforms/Variant';
import VariantStatuses from '../resources/VariantStatuses.json';
import Variants from '../services/Variants';
import VariantModal from './VariantModal';
import VariantsFilters from './VariantsFilters';
import './VariantsList.css';

import type { Translateable } from '../types/Translateable';
import type { Word } from '../types/Word';

type Props = Translateable & {
  buttons?: Array<any>,
  filters?: any,
  onFilterChange: (filter: any) => void,
  publicView: boolean,
  words: Array<Word>
};

const WORD_SEPARATOR = ' ';

const VariantsList = (props: Props) => {
  /**
   * Returns the offset and start_word_id parameters object, or the word_id and filters.
   *
   * @returns {{offset: number, start_word_id: number} | {word_id: number, *}}
   */
  const getParams = useCallback(() => {
    if (props.publicView) {
      return {};
    }

    const anchorProps = getAnchorProps(props.words);
    return anchorProps;
  }, [props.publicView, props.words]);

  /**
   * Renders the status for the passed variant.
   *
   * @param variant
   *
   * @returns {null|*}
   */
  const renderStatus = (variant) => {
    const option = _.findWhere(VariantStatuses, { value: variant.status });

    if (!option) {
      return null;
    }

    return (
      <DescriptorField
        content={variant.status}
        popupContent={option.text}
      />
    );
  };

  /**
   * Renders the type for the passed variant.
   *
   * @param variant
   *
   * @returns {null|*}
   */
  const renderTypes = (variant) => _.map(
    _.sortBy(variant.variant_types_variants, (vtv) => vtv.variant_type.abbreviation.toLowerCase()),
    (option, index) => (
      <DescriptorField
        content={`${option.variant_type.abbreviation}${
          index < variant.variant_types_variants.length - 1 ? ', ' : ''
        }`}
        popupContent={(
          <>
            <Popup.Header>{ option.variant_type.name }</Popup.Header>
            <Popup.Content>{ option.variant_type.description }</Popup.Content>
          </>
        )}
      />
    ),
  );

  const renderAttachmentButton = (variant) => (
    <Modal
      trigger={(
        <Button
          compact
          size='small'
          disabled={!variant.attachments.length}
          basic
          icon='attach'
        />
      )}
      closeIcon
      size='large'
    >
      <Modal.Header>{props.t('VariantsList.columns.attachments')}</Modal.Header>
      <Modal.Content>
        <AttachmentsList
          attachments={variant.attachments}
          viewOnly
        />
      </Modal.Content>
    </Modal>
  );

  const publicColumns = [{
    name: 'chapter_verse',
    label: props.t('Common.columns.chapterVerse'),
    render: (variant) => (
      <ChapterVerse
        chapter={variant.chapter}
        standardReading={variant.standard_reading}
        verse={variant.verse}
      />
    ),
    sortable: true,
    className: 'chapter-verse-cell',
  }];

  const sharedColumns = [{
    className: 'arabic',
    name: 'arabic_with_vowels',
    label: props.t('VariantsList.columns.arabic'),
    sortable: true
  }, {
    name: 'roman_transliteration',
    label: props.t('VariantsList.columns.romanTransliteration'),
    sortable: true,
    className: 'transliteration'
  }, {
    name: 'status',
    label: props.t('VariantsList.columns.status'),
    render: renderStatus.bind(this),
    sortable: true,
    className: 'status-cell',
  }, {
    name: 'variant_type',
    label: props.t('VariantsList.columns.types'),
    render: renderTypes.bind(this),
    sortable: true,
    className: 'type-cell',
  }, {
    className: 'attachments-cell',
    name: 'attachments',
    label: props.t('VariantsList.columns.attachments'),
    render: renderAttachmentButton.bind(this)
  }];

  const adminColumns = [{
    name: 'standard',
    label: props.t('VariantsList.columns.standard'),
    render: (variant) => <BooleanIcon value={variant.standard} />,
    sortable: true
  }];

  const adminListTableProps = {
    actions: [{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }],
    modal: {
      component: VariantModal,
      props: {
        defaults: getParams(),
        required: ['arabic_with_vowels', 'roman_transliteration'],
        words: props.words
      }
    },
    onCopy: (variant) => Variant.toCopy(variant),
    onDelete: (variant) => Variants.delete(variant),
    onSave: (variant) => Variants.save({
      ...variant,
      standard: _.map(props.words,
        (word) => word.arabic_with_vowels).join(WORD_SEPARATOR) === variant.arabic_with_vowels
    }),
    renderDeleteModal: renderDeleteModal.bind(this),
    session: {
      key: 'VariantsList',
      storage: sessionStorage
    }
  };

  /**
   * Sets the filters variable. In the public view and admin view, the VariantsFilters component will be displayed
   * to allow filtering the list of annotations.
   *
   * @type {unknown}
   */
  const filters = useMemo(() => ({
    component: VariantsFilters,
    defaults: {
      ...props.filters || {},
      word_id: props.publicView
        ? (props.words && props.words.length && _.first(props.words).id)
        : undefined
    },
    onChange: props.onFilterChange,
    props: {}
  }), [props.onFilterChange, props.publicView, props.words]);

  const onLoad = useCallback((params) => (
    Variants.fetchAll(_.extend(params, getParams()))
  ), [getParams, props.publicView]);

  return (
    <ScrollableListTable
      className='variants-list'
      collectionName='variants'
      columns={props.publicView ? [...publicColumns, ...sharedColumns] : [...sharedColumns, ...adminColumns]}
      filters={filters}
      {...props.publicView ? { } : adminListTableProps}
      onLoad={onLoad}
      buttons={props.buttons}
      perPageOptions={[10, 25, 50, 100]}
    />
  );
};

export default withTranslation()(VariantsList);
