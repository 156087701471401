// @flow

import uuid from 'uuid';

import type { Variant } from '../types/Variant';
import type { VariantType } from '../types/VariantType';

/**
 * Class for handling transforming variant_types_variant records.
 */
class VariantTypesVariant {
  /**
   * Returns a variant_types_variant object for the passed variant and variant_type.
   *
   * @param variant
   * @param variantType
   *
   * @returns {{uid: *, variant_type_id: number, variant_type: *}}
   */
   createMembership(variant: Variant, variantType: VariantType) {
    return {
      variant_id: variant.id,
      variant: variant,
      variant_type_id: variantType.id,
      variant_type: variantType,
      uid: uuid.v4()
    };
  }
}

export default new VariantTypesVariant();
