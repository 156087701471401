// @flow

import _ from 'underscore';

type ResolveErrorsType = {
  response: {
    data: {
      errors?: any
    }
  }
};

/**
 * Returns a flatted array of all the errors in the passed response.
 *
 * @param errors
 */
const resolveErrors = ({ response: { data: { errors = {} } } }: ResolveErrorsType) => (
  _.flatten(_.map(_.keys(errors), (key) => errors[key]))
);

export default {
  resolveErrors
};
