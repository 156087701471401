// @flow

import { BaseService } from '@performant-software/shared-components';

/**
 * Service class for all word based API calls.
 */
class Words extends BaseService {
  /**
   * Returns the words base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/words';
  }
}

export default new Words();
