// @flow

import { BaseService } from '@performant-software/shared-components';
import Principle from '../transforms/Principle';

/**
 * Service class for all principle based API calls.
 */
class Principles extends BaseService {
  /**
   * Returns the principles base URL.
   *
   * @returns {string}
   */
  getBaseUrl() {
    return '/api/principles';
  }

  /**
   * Returns the principle transform object.
   *
   * @returns {Principle}
   */
  getTransform() {
    return Principle;
  }
}

export default new Principles();
