// @flow

import React, { Component } from 'react';
import { AssociatedDropdown, EmbeddedList } from '@performant-software/semantic-components';
import {
  Form,
  Header,
  Menu,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import FootnoteModal from './FootnoteModal';
import ManualModal from './ManualModal';
import TransmissionModal from './TransmissionModal';
import VariantModal from './VariantModal';
import Languages from '../config/Languages';
import Manuals from '../services/Manuals';
import Transmissions from '../services/Transmissions';
import Variants from '../services/Variants';
import Manual from '../transforms/Manual';
import Transmission from '../transforms/Transmission';
import Variant from '../transforms/Variant';
import { getAnchorProps } from '../utils/Words';
import './AnnotationModal.css';

import type { Annotation } from '../types/Annotation';
import type { EditContainerProps } from '@performant-software/shared-components';
import type { Word } from '../types/Word';

type Props = EditContainerProps & {
  item: Annotation,
  t: (key: string) => string,
  words: Array<Word>
};

type State = {
  tabIndex: number
};

const TAB_INDEX_DETAILS = 0;
const TAB_INDEX_FOOTNOTES = 1;

class AnnotationModal extends Component<Props, State> {
  /**
   * Constructs a new AnnotationModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: TAB_INDEX_DETAILS
    };
  }

  /**
   * Sets the current tab index on the state.
   *
   * @param tabIndex
   */
  onTabClick(tabIndex) {
    this.setState({ tabIndex });
  }

  /**
   * Renders the AnnotationModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        centered={false}
        className='annotation-modal'
        open
        noValidate
        size='small'
      >
        <Modal.Header
          className='modal-header'
        >
          <Header content={this.props.item.id
            ? this.props.t('AnnotationModal.title.editAnnotation')
            : this.props.t('AnnotationModal.title.addAnnotation')}
          />
          <Menu
            float='right'
            secondary
          >
            <Menu.Item
              name='details'
              active={this.state.tabIndex === TAB_INDEX_DETAILS}
              onClick={this.onTabClick.bind(this, TAB_INDEX_DETAILS)}
            />
            <Menu.Item
              name='footnotes'
              active={this.state.tabIndex === TAB_INDEX_FOOTNOTES}
              onClick={this.onTabClick.bind(this, TAB_INDEX_FOOTNOTES)}
            />
          </Menu>
        </Modal.Header>
        <Modal.Content>
          { this.renderDetails() }
          { this.renderFootnotes() }
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }

  /**
   * Renders the details tab.
   *
   * @returns {null|*}
   */
  renderDetails() {
    if (this.state.tabIndex !== TAB_INDEX_DETAILS) {
      return null;
    }

    return (
      <>
        <Header
          className='first-header'
          content={this.props.t('AnnotationModal.labels.variant')}
        />
        <Form.Input
          error={this.props.isError('variant_id')}
          label={this.props.t('AnnotationModal.labels.variant')}
          required={this.props.isRequired('variant_id')}
        >
          <AssociatedDropdown
            className='arabic variant-dropdown'
            collectionName='variants'
            modal={{
              component: VariantModal,
              props: {
                defaults: getAnchorProps(this.props.words),
                required: ['arabic_with_vowels', 'roman_transliteration'],
                words: this.props.words,
              },
              onSave: (variant) => Variants.save(variant).then(({ data }) => data.variant)
            }}
            onSearch={(search) => Variants.fetchAll(_.extend(getAnchorProps(this.props.words), { search }))}
            onSelection={this.props.onAssociationInputChange.bind(this, 'variant_id', 'variant')}
            renderOption={Variant.toDropdownOption.bind(this)}
            searchQuery={this.props.item.variant && this.props.item.variant.arabic_with_vowels}
            value={this.props.item.variant_id}
          />
        </Form.Input>
        <Header
          content={this.props.t('AnnotationModal.headers.transmission')}
        />
        <Form.Input
          error={this.props.isError('transmission_id')}
          label={this.props.t('AnnotationModal.labels.name')}
          required={this.props.isRequired('transmission_id')}
        >
          <AssociatedDropdown
            collectionName='transmissions'
            modal={{
              component: TransmissionModal,
              props: {
                required: ['transmission_memberships']
              },
              onSave: (transmission) => Transmissions
                .save(transmission)
                .then(({ data }) => Transmission.toTransmission(data.transmission))
            }}
            onSearch={(search) => Transmissions.fetchAll({
              search,
              sort_by: 'name',
              per_page: _.isEmpty(search) ? 10 : 0
            })}
            onSelection={this.props.onAssociationInputChange.bind(this, 'transmission_id', 'transmission')}
            renderOption={Transmission.toDropdownOption.bind(this)}
            searchQuery={this.props.item.transmission && this.props.item.transmission.name}
            value={this.props.item.transmission_id}
          />
        </Form.Input>
        <Header
          content={this.props.t('AnnotationModal.headers.manual')}
        />
        <Form.Input
          error={this.props.isError('manual_id')}
          label={this.props.t('AnnotationModal.labels.name')}
          required={this.props.isRequired('manual_id')}
        >
          <AssociatedDropdown
            collectionName='manuals'
            modal={{
              component: ManualModal,
              props: {
                required: ['name']
              },
              onSave: (manual) => Manuals.save(manual).then(({ data }) => data.manual)
            }}
            onSearch={(search) => Manuals.fetchAll({ search })}
            onSelection={this.props.onAssociationInputChange.bind(this, 'manual_id', 'manual')}
            renderOption={Manual.toDropdownOption.bind(this)}
            searchQuery={this.props.item.manual && this.props.item.manual.name}
            value={this.props.item.manual_id}
          />
        </Form.Input>
        <Form.Input
          error={this.props.isError('page_number')}
          label={this.props.t('AnnotationModal.labels.page')}
          onChange={this.props.onTextInputChange.bind(this, 'page_number')}
          required={this.props.isRequired('page_number')}
          value={this.props.item.page_number || ''}
        />
      </>
    );
  }

  /**
   * Renders the body for the passed footnote. If the language of the footnote is Arabic, the content will be
   * displayed in right-to-left orientation.
   *
   * @param body
   * @param language
   * @param index
   *
   * @returns {*}
   */
  renderFootnoteBody({ body, language }, index) {
    return (
      <div
        className={language === Languages.arabic ? 'arabic' : ''}
        key={index}

      >
        { body }
      </div>
    );
  }

  /**
   * Renders the footnotes tab.
   *
   * @returns {null|*}
   */
  renderFootnotes() {
    if (this.state.tabIndex !== TAB_INDEX_FOOTNOTES) {
      return null;
    }

    return (
      <EmbeddedList
        actions={[{
          name: 'edit'
        }, {
          name: 'copy'
        }, {
          name: 'delete'
        }]}
        className='footnotes'
        columns={[{
          name: 'label',
          label: this.props.t('AnnotationModal.footnotes.columns.label')
        }, {
          name: 'language',
          label: this.props.t('AnnotationModal.footnotes.columns.language'),
          resolve: ({ language }) => (language === Languages.arabic
            ? this.props.t('Common.languages.arabic')
            : this.props.t('Common.languages.english'))
        }, {
          name: 'body',
          label: this.props.t('AnnotationModal.footnotes.columns.body'),
          render: this.renderFootnoteBody.bind(this)
        }]}
        items={this.props.item.footnotes}
        modal={{
          component: FootnoteModal,
          props: {
            defaults: {
              language: Languages.english
            },
            required: ['body', 'label']
          }
        }}
        onDelete={this.props.onDeleteChildAssociation.bind(this, 'footnotes')}
        onSave={this.props.onSaveChildAssociation.bind(this, 'footnotes')}
      />
    );
  }
}

export default withTranslation()(AnnotationModal);
