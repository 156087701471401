// @flow

import React, { Component } from 'react';
import {
  DescriptorField,
  EmbeddedList,
  KeyboardField,
  Selectize,
  type EditContainerProps
} from '@performant-software/semantic-components';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Arabic from 'simple-keyboard-layouts/build/layouts/arabic';
import _ from 'underscore';
import AttachmentsList from './AttachmentsList';
import File from '../transforms/File';
import MultiAddButton from './MultiAddButton';
import StringUtils from '../utils/String';
import VariantStatuses from '../resources/VariantStatuses.json';
import VariantTypeModal from './VariantTypeModal';
import VariantTypes from '../services/VariantTypes';
import VariantTypesVariant from '../transforms/VariantTypesVariant';
import VersesModal from './VersesModal';
import './VariantModal.css';

import type { Variant } from '../types/Variant';
import type { VariantType as VariantTypeType } from '../types/VariantType';

type Props = EditContainerProps & { item: Variant };
type State = {
  modalVerses: boolean,
  modalSelectize: boolean
};

const WORD_SEPARATOR = ' ';

class VariantModal extends Component<Props, State> {
  /**
   * Constructs a new VariantModal component.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalVerses: false,
      modalSelectize: false
    };
  }

  /**
   * Adds the passed collection of VariantTypes as variant_types_variants for the current variant.
   *
   * @param variantTypes
   */
  onAddVariantTypes(variantTypes: Array<VariantTypeType>) {
    // Hide the people selectize modal
    this.setState({ modalSelectize: false });

    // Find an existing non-deleted membership
    const findMembership = (variantType) => _.find(
      this.props.item.variant_types_variants,
      (vtv) => !vtv._destroy && vtv.variant_type_id === variantType.id
    );

    const memberships = _.map(
      variantTypes,
      (variantType) => findMembership(variantType)
        || VariantTypesVariant.createMembership(this.props.item, variantType)
    );

    this.props.onMultiAddChildAssociations('variant_types_variants', memberships);
  }

  /**
   * Executes the dropdown search on the passed collection of options.
   *
   * @param options
   * @param value
   *
   * @returns {[]}
   */
  onSearchChange(options, value) {
    const regex = new RegExp(StringUtils.stripDiacritics(value), 'i');

    return _.filter((options), (option) => {
      let include = false;

      const item = _.findWhere(VariantStatuses, { value: option.value });
      const keys = _.keys(item);

      _.each(keys, (key) => {
        const itemValue = StringUtils.stripDiacritics(item[key]);

        if (regex.test(itemValue)) {
          include = true;
        }
      });

      return include;
    });
  }

  /**
   * Renders the VariantModal component.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        className='variant-modal'
        open
        noValidate
        size='small'
      >
        <Modal.Header>
          <Grid columns={2}>
            <Grid.Column>
              <Header content={this.props.item.id
                ? this.props.t('VariantModal.title.editVariant')
                : this.props.t('VariantModal.title.addVariant')}
              />
            </Grid.Column>
            <Grid.Column
              textAlign='right'
            >
              <Button
                basic
                icon='file alternate'
                onClick={() => this.setState({ modalVerses: true })}
              />
              <Dropdown
                basic
                button
                className='dropdown-button'
                icon='cog'
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      const value = this.props.words.map((w) => w.arabic_with_vowels).join(WORD_SEPARATOR);
                      this.props.onTextInputChange('arabic_with_vowels', null, { value });
                    }}
                  >
                    { this.props.t('VariantModal.labels.standard') }
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <KeyboardField
            autoFocus
            error={this.props.isError('arabic_with_vowels')}
            label={this.props.t('VariantModal.labels.arabicWithVowels')}
            layout={Arabic}
            name='arabic_with_vowels'
            onChange={this.props.onTextInputChange.bind(this, 'arabic_with_vowels')}
            required={this.props.isRequired('arabic_with_vowels')}
            value={this.props.item.arabic_with_vowels || ''}
          >
            <input
              className='arabic'
              dir='rtl'
              type='text'
            />
          </KeyboardField>
          <Form.Input
            error={this.props.isError('roman_transliteration')}
            label={this.props.t('VariantModal.labels.romanTransliteration')}
            onChange={this.props.onTextInputChange.bind(this, 'roman_transliteration')}
            required={this.props.isRequired('roman_transliteration')}
            value={this.props.item.roman_transliteration || ''}
          />
          <Form.Dropdown
            error={this.props.isError('status')}
            fluid
            label={this.props.t('VariantModal.labels.status')}
            onChange={this.props.onTextInputChange.bind(this, 'status')}
            options={VariantStatuses.map((s) => ({
              key: s.key,
              value: s.value,
              text: <DescriptorField content={s.value} popupContent={s.text} />
            }))}
            required={this.props.isRequired('status')}
            search={this.onSearchChange.bind(this)}
            selectOnBlur={false}
            selection
            value={this.props.item.status || ''}
          />
          <Form.Input
            error={this.props.isError('variant_types')}
            label={this.props.t('VariantModal.labels.types')}
          >
            <EmbeddedList
              actions={[{
                name: 'delete',
                popup: {
                  content: this.props.t('VariantModal.memberships.remove'),
                  title: this.props.t('EmbeddedList.buttons.remove')
                },
              }]}
              addButton={{
                location: 'top'
              }}
              buttons={[{
                render: (index) => (
                  <MultiAddButton
                    index={index}
                    onClick={() => this.setState({ modalSelectize: true })}
                  />
                )
              }]}
              collectionName='variant_types_variants'
              columns={[{
                name: 'variant_type.name',
                label: this.props.t('VariantModal.memberships.columns.name'),
                resolve: (membership) => membership.variant_type && membership.variant_type.name
              }, {
                name: 'variant_type.description',
                label: this.props.t('VariantModal.memberships.columns.description'),
                resolve: (membership) => membership.variant_type && membership.variant_type.description
              }]}
              items={this.props.item.variant_types_variants}
              onDelete={this.props.onDeleteChildAssociation.bind(this, 'variant_types_variants')}
              onSave={this.props.onSaveChildAssociation.bind(this, 'variant_types_variants')}
            />
          </Form.Input>
          { this.renderVariantTypesSelectize() }
          <Form.Input
            error={this.props.isError('attachments')}
            label={this.props.t('VariantModal.labels.attachments')}
          >
            <AttachmentsList
              attachments={this.props.item.attachments}
              onDelete={this.props.onDeleteChildAssociation.bind(this, 'attachments')}
              onFilesAdded={(files) => {
                _.each(files, (f) => this.props.onSaveChildAssociation('attachments', File.toAttachment(f)));
              }}
            />
          </Form.Input>
          <VersesModal
            onClose={() => this.setState({ modalVerses: false })}
            visible={this.state.modalVerses}
            words={this.props.words}
          />
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }


  /**
   * Renders the variant types Selectize component.
   *
   * @returns {null|*}
   */
  renderVariantTypesSelectize() {
    if (!this.state.modalSelectize) {
      return null;
    }

    return (
      <Selectize
        collectionName='variant_types'
        onClose={() => this.setState({ modalSelectize: false })}
        onLoad={(params) => VariantTypes.fetchAll(_.extend(params, { sort_by: 'name' }))}
        onSave={this.onAddVariantTypes.bind(this)}
        modal={{
          component: VariantTypeModal,
          onSave: (variantType) => (
            VariantTypes
              .save(variantType)
              .then(({ data }) => data.variant_type)
          ),
          props: {
            required: ['name', 'abbreviation', 'description']
          }
        }}
        renderItem={(variantType) => variantType.name}
        selectedItems={_.map(_.filter(this.props.item.variant_types_variants,
          (vtv) => !vtv._destroy), (vtv) => vtv.variant_type)}
        title={this.props.t('VariantModal.labels.types')}
      />
    );
  }
}

export default withTranslation()(VariantModal);
