// @flow

import React, { Component } from 'react';
import { ColorButton, ColorPickerModal } from '@performant-software/semantic-components';
import {
  Form,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import './PrincipleModal.css';

import type { EditContainerProps } from '@performant-software/shared-components/types';
import type { Principle } from '../types/Principle';

type Props = EditContainerProps & { item: Principle };

type State = {
  modalColor: boolean
};

class PrincipleModal extends Component<Props, State> {
  /**
   * Constructs a new PrincipleModal.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalColor: false
    };
  }

  /**
   * Closes the color picker modal and sets the selected color on the state.
   *
   * @param color
   * @param e
   */
  onColorChange(color, e) {
    this.setState({ modalColor: false });
    this.props.onTextInputChange('color', e, { value: color.hex });
  }

  /**
   * Renders the PrincipleModal.
   *
   * @returns {*}
   */
  render() {
    return (
      <Modal
        as={Form}
        open
        noValidate
        size='small'
      >
        <Modal.Header content={this.props.item.id
          ? this.props.t('PrincipleModal.title.editPrinciple')
          : this.props.t('PrincipleModal.title.addPrinciple')}
        />
        <Modal.Content>
          <Form.Input
            autoFocus
            error={this.props.isError('name')}
            label={this.props.t('PrincipleModal.labels.name')}
            onChange={this.props.onTextInputChange.bind(this, 'name')}
            required={this.props.isRequired('name')}
            value={this.props.item.name || ''}
          />
          <Form.Input
            error={this.props.isError('rule')}
            label={this.props.t('PrincipleModal.labels.rule')}
            onChange={this.props.onTextInputChange.bind(this, 'rule')}
            required={this.props.isRequired('rule')}
            value={this.props.item.rule || ''}
          />
          <Form.Input
            error={this.props.isError('color')}
            label={this.props.t('PrincipleModal.labels.color')}
            required={this.props.isRequired('color')}
          >
            <ColorButton
              color={this.props.item.color}
              onClick={() => this.setState({ modalColor: true })}
            />
          </Form.Input>
          <ColorPickerModal
            color={this.props.item.color}
            onClose={() => this.setState({ modalColor: false })}
            onSave={this.onColorChange.bind(this)}
            open={this.state.modalColor}
          />
        </Modal.Content>
        { this.props.children }
      </Modal>
    );
  }
}

export default withTranslation()(PrincipleModal);
