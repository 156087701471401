// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Confirm, Message, Modal } from 'semantic-ui-react';

type Props = {
  count: number,
  onCancel: () => void,
  onConfirm: () => void,
  t: (key: string, params?: any) => string
};

const AnnotateableDeleteModal = (props: Props) => (
  <Confirm
    content={(
      <Modal.Content>
        <Message
          content={props.count && props.count > 0
            ? props.t('AnnotateableDeleteModal.deleteWarning.content')
            : props.t('AnnotateableDeleteModal.deleteSafe.content')}
          header={props.count && props.count > 0
            ? props.t('AnnotateableDeleteModal.deleteWarning.header', { count: props.count })
            : props.t('AnnotateableDeleteModal.deleteSafe.header', { count: props.count })}
          icon={props.count && props.count > 0 ? 'warning sign' : 'check circle outline'}
          positive={!props.count || props.count === 0}
          warning={props.count && props.count > 0}
        />
      </Modal.Content>
    )}
    onCancel={props.onCancel}
    onConfirm={props.onConfirm}
    open
  />
);

export default withTranslation()(AnnotateableDeleteModal);
