// @flow

import React from 'react';
import { DescriptorField } from '@performant-software/semantic-components';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import { renderDeleteModal } from '../utils/Annotations';
import ScrollableListTable from '../components/ScrollableListTable';
import Session from '../services/Session';
import TransmissionModal from '../components/TransmissionModal';
import TransmissionsService from '../services/Transmissions';
import Transmission from '../transforms/Transmission';
import './Transmissions.css';

type Props = {
  t: (key: string, props: any) => string
};

const ERROR_TRANSMISSION_MEMBERSHIPS = 'Transmission members and order must be unique';

const Transmissions = (props: Props) => (
  <ScrollableListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'copy'
    }, {
      name: 'delete'
    }]}
    className='transmissions'
    collectionName='transmissions'
    columns={[{
      className: 'name',
      name: 'name',
      label: props.t('Transmissions.columns.name'),
      render: (transmission) => (
        <DescriptorField
          className='arabic'
          content={transmission.name}
          popupContent={transmission.arabic_name}
        />
      ),
      sortable: true
    }]}
    modal={{
      component: TransmissionModal,
      props: {
        required: ['transmission_memberships'],
        resolveValidationError: (error, item) => {
          if (error === ERROR_TRANSMISSION_MEMBERSHIPS) {
            const memberships = _.filter(item.transmission_memberships, (tm) => !tm._destroy);

            return {
              transmission_memberships: props.t('Transmissions.errors.duplicate', {
                count: memberships && memberships.length
              })
            };
          }

          return null;
        }
      }
    }}
    onCopy={(transmission) => Transmission.toCopy(transmission)}
    onDelete={(transmission) => TransmissionsService.delete(transmission)}
    onLoad={(params) => TransmissionsService.fetchAll(params)}
    onSave={(transmission) => TransmissionsService.save(transmission)}
    perPageOptions={[10, 25, 50, 100]}
    renderDeleteModal={renderDeleteModal.bind(this)}
    session={{
      key: 'transmissions',
      storage: Session.storage
    }}
  />
);

export default withTranslation()(Transmissions);
