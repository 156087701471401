// @flow

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Container,
  Menu
} from 'semantic-ui-react';
import _ from 'underscore';
import AnnotationsList from '../components/AnnotationsList';
import VariantsList from '../components/VariantsList';
import VersesModal from '../components/VersesModal';
import Words from '../services/Words';
import './Edit.css';

import type { Word } from '../types/Word';

type Props = {
  location: {
    state: {
      wordIds: Array<number>
    }
  },
  t: (key: string, params?: any) => string
};

type State = {
  modalVerses: boolean,
  tabIndex: number,
  words: Array<Word>
};

const SESSION_KEY = 'Edit';

const TAB_INDEX_ANNOTATIONS = 0;
const TAB_INDEX_VARIANTS = 1;

class Edit extends Component<Props, State> {
  /**
   * Constructs a new Edit page.
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      modalVerses: false,
      tabIndex: TAB_INDEX_ANNOTATIONS,
      words: []
    };
  }

  /**
   * Loads the selected words.
   */
  componentDidMount() {
    this.restoreSession();

    const { wordIds } = this.props.location.state;

    Words
      .fetchAll({ ids: wordIds })
      .then(({ data: { words } }) => {
        this.setState({ words });
      });
  }

  /**
   * Remove the session storage if the component is going to unmount. The user could return to this list for
   * a new set of words, so we don't want to use the session values from a stale list. To persist filters,
   * we'll only remove the "page" property.
   */
  componentWillUnmount() {
    this.resetFilters('DataList.AnnotationsList');
    this.resetFilters('DataList.VariantsList');
  }

  /**
   * Sets the passed tab index on the state and stores it in the session storage.
   *
   * @param tabIndex
   */
  onTabClick(tabIndex) {
    this.setState({ tabIndex }, this.setSession.bind(this));
  }

  /**
   * Renders the variants and annotations.
   *
   * @returns {null|*}
   */
  render() {
    if (!this.state.words.length) {
      return null;
    }

    return (
      <div
        className='edit-page'
      >
        <Container>
          { this.renderMenu() }
          { this.renderAnnotations() }
          { this.renderVariants() }
          { this.renderVersesModal() }
        </Container>
      </div>
    );
  }

  /**
   * Renders the annotations tab.
   *
   * @returns {null|*}
   */
  renderAnnotations() {
    if (this.state.tabIndex !== TAB_INDEX_ANNOTATIONS) {
      return null;
    }

    return (
      <div
        className='tab-container'
      >
        <AnnotationsList
          words={this.state.words}
        />
      </div>
    );
  }

  /**
   * Renders the tab menu.
   *
   * @returns {*}
   */
  renderMenu() {
    const chapter = _.first(this.state.words).verse.chapter.number;
    const verseNumbers = _.unique(this.state.words.map((word) => word.verse.number));
    const verse = verseNumbers.length === 1 ? _.min(verseNumbers) : `${_.min(verseNumbers)}-${_.max(verseNumbers)}`;
    const text = this.state.words.map((word) => `${word.arabic_without_vowels} `);

    return (
      <Menu tabular>
        <Menu.Item
          name={this.props.t('Edit.tabs.annotations')}
          active={this.state.tabIndex === TAB_INDEX_ANNOTATIONS}
          onClick={this.onTabClick.bind(this, TAB_INDEX_ANNOTATIONS)}
        />
        <Menu.Item
          name={this.props.t('Edit.tabs.variants')}
          active={this.state.tabIndex === TAB_INDEX_VARIANTS}
          onClick={this.onTabClick.bind(this, TAB_INDEX_VARIANTS)}
        />
        <Menu.Menu
          position='right'
        >
          <Menu.Item>
            <Breadcrumb>
              <Breadcrumb.Section
                as={Link}
                content={this.props.t('Edit.chapter', { chapter })}
                to='/entry/text'
              />
              <Breadcrumb.Divider
                icon='right angle'
              />
              <Breadcrumb.Section
                content={this.props.t('Edit.verses', { verse, count: verseNumbers.length })}
                onClick={() => this.setState({ modalVerses: true })}
              />
              <Breadcrumb.Divider
                icon='right angle'
              />
              <Breadcrumb.Section
                active
                className='arabic'
                content={text}
                dir='rtl'
              />
            </Breadcrumb>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }

  /**
   * Renders the variants tab.
   *
   * @returns {null|*}
   */
  renderVariants() {
    if (this.state.tabIndex !== TAB_INDEX_VARIANTS) {
      return null;
    }

    return (
      <div
        className='tab-container'
      >
        <VariantsList
          words={this.state.words}
        />
      </div>
    );
  }

  /**
   * Renders the verses modal component.
   *
   * @returns {*}
   */
  renderVersesModal() {
    return (
      <VersesModal
        onClose={() => this.setState({ modalVerses: false })}
        visible={this.state.modalVerses}
        words={this.state.words}
      />
    );
  }

  /**
   * Removes the "page" property from the session storage.
   *
   * @param key
   */
  resetFilters(key) {
    const storage = sessionStorage.getItem(key) || '{}';
    const filters = JSON.parse(storage);

    const newFilters = JSON.stringify(_.omit(filters, 'page'));
    sessionStorage.setItem(key, newFilters);
  }

  /**
   * Restores the tab index from the session storage.
   */
  restoreSession() {
    const session = sessionStorage.getItem(SESSION_KEY) || '{}';

    const { tabIndex } = JSON.parse(session);
    this.setState({ tabIndex: tabIndex || TAB_INDEX_ANNOTATIONS });
  }

  /**
   * Sets the tab index in the session storage.
   */
  setSession() {
    const { tabIndex } = this.state;
    sessionStorage.setItem(SESSION_KEY, JSON.stringify({ tabIndex }));
  }
}

export default withTranslation()(Edit);
