// @flow

import _ from 'underscore';

import type { Location as LocationType } from '../types/Location';

/**
 * Class for handling transforming location records.
 */
class Location {
  /**
   * Transforms the passed location into a dropdown option.
   *
   * @param location
   *
   * @returns {{text: *, value: *, key: *}}
   */
  toDropdownOption(location: LocationType = {}) {
    return {
      key: location.id,
      value: location.id,
      text: location.name
    };
  }

  /**
   * Returns the location object to be sent to the server on POST/PUT requests.
   *
   * @param location
   *
   * @returns {*}
   */
  toPayload(location: LocationType) {
    return {
      location: _.pick(location, 'name', 'arabic_name', 'lat', 'lng')
    };
  }
}

export default new Location();
