// @flow

import uuid from 'uuid';

import type { Person } from '../types/Person';

/**
 * Class for handling transforming transmission_membership records.
 */
class TransmissionMembership {
  /**
   * Returns a transmission_membership object for the passed person.
   *
   * @param person
   *
   * @returns {{uid: *, certitude: boolean, person: *, person_id: *}}
   */
  fromPerson(person: Person) {
    return {
      certitude: true,
      person,
      person_id: person.id,
      uid: uuid.v4()
    };
  }
}

export default new TransmissionMembership();
