// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Grid, Header } from 'semantic-ui-react';

import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  content: string,
  onReset: () => void
};

const FiltersHeader = (props: Props) => (
  <Grid
    columns={2}
  >
    <Grid.Column>
      <Header
        content={props.content}
      />
    </Grid.Column>
    <Grid.Column
      textAlign='right'
    >
      <Button
        color='red'
        content={props.t('FiltersHeader.buttons.clear')}
        icon='times circle'
        onClick={props.onReset}
      />
    </Grid.Column>
  </Grid>
);

export default withTranslation()(FiltersHeader);
