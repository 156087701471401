// @flow

import React from 'react';
import { AssociatedDropdown } from '@performant-software/semantic-components';
import {
  Form,
  Modal
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import People from '../services/People';
import PersonTransform from '../transforms/Person';
import PersonModal from './PersonModal';
import './PersonModal.css';

import type { Authorship } from '../types/Authorship';
import type { EditContainerProps } from '@performant-software/shared-components/types';

type Props = EditContainerProps & {
  item: Authorship,
  t: (key: string) => string
};

const AuthorshipModal = (props: Props) => (
  <Modal
    as={Form}
    open
    noValidate
    size='small'
  >
    <Modal.Header content={props.item.id
      ? props.t('AuthorshipModal.title.editAuthor')
      : props.t('AuthorshipModal.title.addAuthor')}
    />
    <Modal.Content>
      <Form.Input
        error={props.isError('person_id')}
        label={props.t('AuthorshipModal.labels.author')}
        required={props.isRequired('person_id')}
      >
        <AssociatedDropdown
          collectionName='people'
          modal={{
            component: PersonModal,
            props: {
              required: ['name', 'abbreviation']
            },
            onSave: (person) => People.save(person).then(({ data }) => data.person)
          }}
          onSearch={(search) => People.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'person_id', 'person')}
          renderOption={PersonTransform.toDropdownOption.bind(this)}
          searchQuery={props.item.person && props.item.person.abbreviation}
          value={props.item.person_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('role')}
        label={props.t('AuthorshipModal.labels.role')}
        onChange={props.onTextInputChange.bind(this, 'role')}
        required={props.isRequired('role')}
        value={props.item.role || ''}
      />
    </Modal.Content>
    { props.children }
  </Modal>
);

export default withTranslation()(AuthorshipModal);
