// @flow

import React, { useMemo } from 'react';
import { Label, Popup } from 'semantic-ui-react';
import { isEmpty } from 'underscore';
import Colors from '../utils/Colors';

type Props = {
  disablePopup: boolean,
  status: Object,
  isPrinciple?: boolean,
};

const DEFAULT_STATUS = {
  abbreviation: 'NS',
  color: 'grey',
  text: 'No Status'
};

const StatusChip = (props: Props) => {
  const { disablePopup, isPrinciple } = props;

  /**
   * Sets the status variable based on the status prop. If the prop is empty, the default will be used.
   *
   * @type {Object|{color: string, text: string, abbreviation: string}}
   */
  const status = useMemo(() => (
    !isEmpty(props.status) ? props.status : DEFAULT_STATUS
  ), [props.status]);

  /**
   * Sets the text color variable based on the above status.
   *
   * @type {*}
   */
  const color = useMemo(() => Colors.getTextColor(status.color), [status]);

  return (
    <Popup
      size='mini'
      disabled={disablePopup}
      content={status.text}
      position='left center'
      mouseEnterDelay={400}
      trigger={(
        <Label
          circular
          style={{
            backgroundColor: status.color,
            color,
            cursor: 'default',
            margin: `${isPrinciple ? 'auto 2px 4px 2px' : ''}`
          }}
          size='mini'
        >
          { status.abbreviation }
        </Label>
      )}
    />
  );
};

StatusChip.defaultProps = {
  isPrinciple: false
};

export default StatusChip;
